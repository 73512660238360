import { FC, useState } from "react"
import { Button, Spinner } from "react-bootstrap"
import { useCreateEncounterMutator, useUpdateActMutator } from "../../stores/tale/campaign/CampaignMutators"
import { ActResource, IdResource, ProposalResource, TaleIdResource } from "../../generated/BackendApiClient"
import { TaleEncounterView } from "./TaleEncounterView"
import { ProposalGenerator } from "../ProposalGenerator"
import { HasTalePermission } from "../../util/Permission"

interface TaleActViewProps {
    act: ActResource
    campaignId: IdResource
    taleId: TaleIdResource
}

const encounterTypes = [ "Battle", "Riddle", "SocialInteraction", "BossFight" ]

export const TaleActView: FC<TaleActViewProps> = ({ act, campaignId, taleId }) => {

    const [encounterType, setEncounterType] = useState<string>("Battle")

    const { updateActMutator, updatingAct } = useUpdateActMutator()
    const { createEncounterMutator, creatingEncounter } = useCreateEncounterMutator()

    const createEncounter = async (proposal: ProposalResource) => createEncounterMutator.mutateAsync({ campaignId: campaignId, taleId: taleId, actId: act.id, encounterType: encounterType, proposal })

    const [editing, setEditing] = useState<boolean>(false)

    const saveOrEditAct = async () => {
        if (editing) {
            await updateActMutator.mutateAsync({ act, campaignId, taleId })
        }

        setEditing(!editing)
    }

    return (
        <div id={'act_' + act.actSummary.name}>
            <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "16px" }}>
                <h3>{act.actSummary.name}</h3>
                <p style={{ marginBottom: "8px", marginLeft: "12px" }}>Act</p>
            </div>
            <div onBlur={(event) => act.actSummary.description = event.currentTarget.innerText} suppressContentEditableWarning={true}
                contentEditable={editing}>
                {act.actSummary.description}
            </div>
            {HasTalePermission(taleId, "EditTale") ? <Button style={{display: "flex"}} disabled={updatingAct} onClick={saveOrEditAct}>{editing ? "Save" : "Edit Act"}</Button> : <></>}
            {updatingAct && <Spinner />}
            {act.encounters.map(encounter => <TaleEncounterView key={act.actSummary.name} encounter={encounter} actId={act.id} campaignId={campaignId} taleId={taleId} />)}
            {creatingEncounter ?<div>Creating Encounter... <Spinner /></div> : <></>}
            {(!creatingEncounter && HasTalePermission(taleId, "EditTale")) ? 
            <div>
                <select name="encouterType" value={encounterType} onChange={(e) => setEncounterType(e.target.value)}>
                    {encounterTypes.map(type => <option key={type} value={type}>{type}</option>)}
                </select>
                <ProposalGenerator taleId={taleId} queryParams={{campaignId: campaignId.identifier, actId: act.id.identifier, encounterType: encounterType}} onSelectedProposal={createEncounter} costSelection={c => c?.encounterCreation} type="Encounter"/>
            </div> : <></>}
        </div>
    )
}