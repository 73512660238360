import { useMutation } from "@tanstack/react-query"
import { useUserToken } from "../token/TokenQuery"
import { GetBackendClient } from "../../services/BackendClientFactory"

export const usePaymentMutator = () => {
    const { userToken, isAuthenticated } = useUserToken()

    const paymentMutation = useMutation({
        mutationFn: async (coinAmount: number) => {
            if(!isAuthenticated){
                throw new Error("Not authenticated!")
            }

            var response = await GetBackendClient(userToken).buyCoins.buyCoins({coinAmount: coinAmount.toString()})
            const redirectUrl = await response.text()
            return redirectUrl
        },
        onSuccess: (redirectUrl) => {
            console.log("redirecting...", redirectUrl)
            window.location.href = redirectUrl
        }
    })

    return { paymentMutation }
}