import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './style.scss';
import { App } from './App';
import { QueryClientProvider } from '@tanstack/react-query';
import { MsalProvider } from '@azure/msal-react';
import { SetupConfiguration, setUp } from './Setup';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleConfig } from './dtos/GoogleConfig';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

setUp().then(config => render(config))

const render = (config: SetupConfiguration) => {
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={config.queryClient}>
        <MsalProvider instance={config.msalInstance}>
          <GoogleOAuthProvider clientId={googleConfig.clientId}>
            <App />
          </GoogleOAuthProvider>
        </MsalProvider>
      </QueryClientProvider>
    </React.StrictMode>
  )
}