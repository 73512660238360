import { useState, useEffect, FC } from "react";
import { usePaymentMutator } from "../stores/payment/PaymentMutators";
import { Button } from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";
import { invalidateCoins } from "../stores/user/resources/UserResourceMutators";

const ProductDisplay: FC = () => {

  const { paymentMutation } = usePaymentMutator()
  const [coinAmount, setCoinAmount] = useState(100)
  const [isValid, setIsValid] = useState(true)

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    setCoinAmount(+value ?? 0)
    if (Number.isInteger(Number(value)) && Number(value) > 0 && Number(value) % 100 === 0) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }

  return (
    <section>
      <div className="description">
        <h4>Buy more...</h4>
        <div style={{ display: 'flex', flex: 'row' }}>
          <input
            type="number"
            value={coinAmount}
            onChange={handleChange}
            step={100}
            min={100}
            placeholder="Enter a multiple of 100"
          />
          <Button disabled={!isValid} onClick={() => paymentMutation.mutate(coinAmount)}>
            Buy {isValid && `(${coinAmount * 0.005}€)`}
          </Button>
        </div>
      </div>
      {!isValid && <p style={{ color: 'red' }}>Please enter a number that is a multiple of 100 and greater than 0.</p>}
    </section>
  )
}

const Message = ({ message }: { message: string }) => (
  <section>
    <p>{message}</p>
  </section>
);

export const StripePayment: FC = () => {
  const [message, setMessage] = useState("")
  const queryClient = useQueryClient()


  useEffect(() => {

    const query = new URLSearchParams(window.location.search)

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation and your coins soon.")
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      )
    }
  }, [])

  const acknowledgePayment = () => {
    setMessage("")
    invalidateCoins(queryClient)
    setTimeout(() => invalidateCoins(queryClient), 1000) //Hack to hope that hook was called by then
  }

  return message ? (
    <>
      <Message message={message} />
      <Button onClick={acknowledgePayment}>Continue</Button>
    </>
  ) : (
    <ProductDisplay />
  );
}