import { useQueries, useQuery } from "@tanstack/react-query"
import { useUserToken } from "../token/TokenQuery"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { OpenAiApiLlmUsage } from "../../generated/BackendApiClient"

export const llmUsageKey = "llm-usage"

export const GetDayKey = (day: Date) => day.toISOString().split('T')[0]

const GetUsageOfDay = (userToken: string | undefined, dayKey: string) => async () => {
    const resp = await GetBackendClient(userToken).telemetry.getLlmCosts(dayKey)
    return resp.data
}

export const groupByModelAndFunction = (usages: OpenAiApiLlmUsage[]) => {
    const modelUsageMap = new Map<string, Map<string, OpenAiApiLlmUsage[]>>()

    usages.forEach(usage => {
        const modelUsage = modelUsageMap.get(usage.model) ?? new Map<string, OpenAiApiLlmUsage[]>()
        modelUsage.set(usage.functionName, [...(modelUsage.get(usage.functionName) ?? []), usage])
        modelUsageMap.set(usage.model, modelUsage)
    })

    return modelUsageMap
}

export const useLlmUsageQuery = (day: Date) => {
    const { userToken, isAuthenticated } = useUserToken()
    const dayKey = GetDayKey(day)

    const { data } = useQuery({
        queryKey: [llmUsageKey, dayKey],
        enabled: isAuthenticated,
        queryFn: GetUsageOfDay(userToken, dayKey)
    })

    return { usages: data }
}

export const useLlmUsageOfLastWeekQuery = () => {
    const { userToken, isAuthenticated } = useUserToken()
    const days = Array.from({ length: 7 }, (_, i) => new Date(Date.now() - i * 24 * 60 * 60 * 1000))

    const usageQueries = useQueries({
        queries: days.map((day) => {
            const dayKey = GetDayKey(day)
            return {
                queryKey: [llmUsageKey, dayKey],
                enabled: isAuthenticated,
                queryFn: GetUsageOfDay(userToken, dayKey),
            }
        }),
    })

    return { usages: usageQueries.flatMap(usage => usage.data ?? []) }
}