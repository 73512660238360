import { useMutation, useQueryClient } from "@tanstack/react-query"
import { userTokenKey } from "./TokenQuery"
import { useMsal } from "@azure/msal-react"
import { AuthenticationResponseResource } from "../../generated/BackendApiClient"
import { googleTokenKey } from "./GoogleTokenQuery"

export const useUserTokenMutator = () => {
    const queryClient = useQueryClient()

    const userTokenMutation = useMutation({
        mutationFn: async (userToken: AuthenticationResponseResource | undefined) => {
            queryClient.setQueryData([userTokenKey], userToken)
        },
    })

    return { userTokenMutation }
}

export const useGoogleTokenMutator = () => {
    const queryClient = useQueryClient()

    const googleTokenMutaton = useMutation({
        mutationFn: async (googleToken: string | undefined) => {
            queryClient.setQueryData([googleTokenKey], googleToken)
        },
    })

    return { googleTokenMutaton }
}

export const useLogoutMutator = () => {
    const { instance } = useMsal()

    const logoutMutation = useMutation({
        mutationFn: async () => {
            sessionStorage.removeItem("REACT_QUERY_OFFLINE_CACHE")
            instance.clearCache()
            window.location.href = "/"
        },
    })

    return { logoutMutation }
}