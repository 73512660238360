import { jwtDecode } from "jwt-decode"
import { DummyToken } from "../services/BackendClientMock"
import { ForceUserAdminRole } from "./Envs"

const DummyTokenData = {
    User: {
        UserId: { Id: "0815" },
        UserName: "Max Mustermann",
        Roles: ["Beta-Tester"],
        IsAdmin: ForceUserAdminRole,
        IsBetaTester: false
    }
} as UserAuthTokenContent

export interface User {
    UserId: {
        Id: string
    },
    Roles: string[],
    IsAdmin: boolean,
    IsBetaTester: boolean
}

interface UserAuthTokenContent {
    User: User
}

export const DecodeToken = (token: string): UserAuthTokenContent => {
    if (token === DummyToken) {
        return DummyTokenData
    }

    const decoded = jwtDecode(token) as any;

    const idClaim = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
    const roleClaim = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    const roles = (decoded[roleClaim] as string).split(",")
    const user: User = {
        UserId: { Id: decoded[idClaim] as string },
        Roles: roles,
        IsAdmin: roles.some(role => role === "Global-Admin") || ForceUserAdminRole,
        IsBetaTester: roles.some(role => role === "Beta-Tester")
    }

    return { User: user }
}