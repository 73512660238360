import { FC } from "react"
import './Tavern.css'
import { faBeer } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, CardHeader, CardBody } from "react-bootstrap"
import { PageComponent } from "../../components/PageComponent"

export const Tavern: FC = () => {

    return (
        <PageComponent
            backgroundImage={process.env.PUBLIC_URL + '/img/tavern.png'}
            characterImage={process.env.PUBLIC_URL + '/img/barkeeper.png'}
            characterWidthOf12={5}
        >
            <Card>
                <CardHeader><h3>The Tavern <FontAwesomeIcon icon={faBeer} /></h3></CardHeader>
                <CardBody>
                    Nothing here yet...
                </CardBody>
            </Card>
        </PageComponent>
    )
}