import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

interface UploadButtonProps {
    onFileUpload: (base64File: string) => void;
}

export const UploadButton: React.FC<UploadButtonProps> = ({ onFileUpload }) => {
    const [showFileInput, setShowFileInput] = useState(false);

    const handleButtonClick = () => {
        setShowFileInput(true);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result) {
                    onFileUpload(reader.result.toString());
                }
                setShowFileInput(false);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <Button onClick={handleButtonClick}>Upload File</Button>
            {showFileInput && (
                <input type="file" onChange={handleFileChange} />
            )}
        </div>
    );
};
