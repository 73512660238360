import { FC } from "react";
import { groupByModelAndFunction, useLlmUsageOfLastWeekQuery } from "../../stores/telemetry/LlmUsageQueries";
import { useRefreshTodaysLLMUsage } from "../../stores/telemetry/LLMUsageMutations";
import { Button, Card, Table } from "react-bootstrap";
import './LlmUsageComponent.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";

const dollarToString = (dollar: number) => {
    if (dollar > 0.1) {
        return `${dollar.toFixed(2)}$`
    } else {
        return `${(dollar * 100).toFixed(2)}ct`
    }
}

const spanToSeconds = (span: string): number => {
    const [hours, minutes, seconds] = span.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
};

export const LlmUsageComponent: FC = () => {

    const { refreshTodaysUsageMutator } = useRefreshTodaysLLMUsage()
    const { usages } = useLlmUsageOfLastWeekQuery()
    const lookup = groupByModelAndFunction(usages)
    console.table(usages)
    return (
        <div className="LlmUsageComponent">
            <h2>7 Day LLM Usages <Button onClick={() => refreshTodaysUsageMutator.mutate()}>Refresh Today <FontAwesomeIcon icon={faRefresh} /></Button></h2>
            <div className="model-cards">
                {[...lookup.entries()].map(([model, usageByFunction]) => (
                    <Card className="model-card">
                        <Card.Header>
                            <h3>{model}</h3>
                        </Card.Header>
                        <Card.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Function</th>
                                        <th className="right">Costs</th>
                                        <th className="right">Avg. Costs</th>
                                        <th className="right">Avg. Time</th>
                                        <th className="right">Min Time</th>
                                        <th className="right">Max Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...usageByFunction.entries()].map(([functionName, usage]) => {
                                        const sum = usage.map(u => u.costsInDollar ?? 0).reduce((a, b) => a + b)
                                        const avg = sum / usage.length
                                        const seconds = usage.map(u => spanToSeconds(u.processingTime))
                                        const totalTime = seconds.reduce((a, b) => a + b)
                                        const minTime = Math.min(...seconds)
                                        const maxTime = Math.max(...seconds)
                                        return (
                                            <tr>
                                                <td>{usage.length}x</td>
                                                <td>{functionName}</td>
                                                <td className="right">{dollarToString(sum)}</td>
                                                <td className="right">{dollarToString(avg)}</td>
                                                <td className="right">{(totalTime / usage.length).toFixed(2)}sec</td>
                                                <td className="right">{minTime.toFixed(2)}sec</td>
                                                <td className="right">{maxTime.toFixed(2)}sec</td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </div>
    )
}