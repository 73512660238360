import { FC } from "react";
import { Table } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { TaleResource } from "../generated/BackendApiClient";

interface TaleListProps {
    tales: TaleResource[]
}

export const TaleList: FC<TaleListProps> = ({ tales }) => {

    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", width: '90%' }}>
            <Table>
                <thead>
                    <tr>
                        <th>Talename</th>
                    </tr>
                </thead>
                <tbody>
                    {tales.length === 0 ?
                        <tr><td colSpan={2}>No tales created yet...</td></tr> :
                        tales.map(tale =>
                            <tr key={tale.id.id}>
                                <td><Link to={`/tale/${tale.id.id}`}>{tale.world.name}</Link></td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </div>
    )
}