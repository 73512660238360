import { PublicClientApplication } from "@azure/msal-browser"
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister"
import { QueryClient } from "@tanstack/react-query"
import { persistQueryClient } from "@tanstack/react-query-persist-client"
import { msalConfig } from "./dtos/MsalConfig"
import { Minute } from "./util/Time"
import { SkippAuthentication, UseMock } from "./util/Envs"
import { userTokenKey } from "./stores/token/TokenQuery"
import { DummyAuthState } from "./services/BackendClientMock"
import { msTokenKey } from "./stores/token/MsTokenQuery"

export type SetupConfiguration = {
    queryClient: QueryClient,
    msalInstance: PublicClientApplication
}

export const setUp = async (): Promise<SetupConfiguration> => {
    if (UseMock) {
        await enableMocking()
    }

    if (SkippAuthentication) {
        skippAuth(queryClient)
    }

    return { queryClient: queryClient, msalInstance: msalInstance }
}

const skippAuth = (queryClient: QueryClient) => {
    const msToken = 'msDummyToken'
    queryClient.setQueryData([msTokenKey], msToken)
    queryClient.setQueryData([userTokenKey], DummyAuthState)
}

const enableMocking = async () => {
    const { apiMockWorker: server } = await import('./services/BackendClientMock')
    return server.start()
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 10 * Minute,
            retry: 2,
            retryDelay: attempt => Math.min(200 * 2 ** attempt, 5000)
        }
    }
})

const sessionStoragePersister = createSyncStoragePersister({ storage: window.sessionStorage })

persistQueryClient({
    queryClient: queryClient,
    persister: sessionStoragePersister,
    maxAge: Infinity
})

const msalInstance = new PublicClientApplication({ auth: msalConfig })