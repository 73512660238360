
const toBool = (str: string | undefined | null) => str?.toLocaleLowerCase() === 'true'

const getBackendUrl = (): string => {
    const org = window.location.origin

    if (org.startsWith("https://app.dungeon-tinker.com")) return process.env.REACT_APP_API_BASEURL_PROD!
    if (org.startsWith("https://dev.dungeon-tinker.com")) return process.env.REACT_APP_API_BASEURL_DEV!

    return process.env.REACT_APP_API_BASEURL_LOCAL!
}

export const SkippAuthentication = toBool(process.env.REACT_APP_SKIPP_AUTH)
export const UseMock = toBool(process.env.REACT_APP_USE_MOCK)
export const API_BASEURL = UseMock ? 'http://localbackendmock' : getBackendUrl()
export const IgnoreBetaTesterRole = toBool(process.env.REACT_APP_IGNORE_BETA_TESTER_ROLE)
export const ForceUserAdminRole = toBool(process.env.REACT_APP_FORCE_ADMIN_ROLE)
