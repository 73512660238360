import { FC } from "react"
import { EnemyResource } from "../../generated/BackendApiClient"

interface TaleEnemyViewProps {
    enemy: EnemyResource
}
export const TaleEnemyView: FC<TaleEnemyViewProps> = ({ enemy }) => {
    return (
        <div>
            <div style={{ display: "flex", alignItems: "flex-end", marginTop: "16px" }}>
                <h5>{enemy.species}</h5>
                <p style={{ marginBottom: "8px", marginLeft: "12px" }}>Enemy</p>
            </div>
        </div>
    )
}