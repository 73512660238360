export const ImageToBase64 = (imageUrl: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = imageUrl

        img.onload = () => {
            const canvas = document.createElement("canvas")
            canvas.width = img.width
            canvas.height = img.height

            const ctx = canvas.getContext("2d")
            ctx!.drawImage(img, 0, 0, img.width, img.height)

            const base64Data = canvas.toDataURL("image/png")
            resolve(base64Data.slice(base64Data.indexOf(',') + 1));
        }

        img.onerror = (error) => reject(error)
    })
}