import { useMutation, useQueryClient } from "@tanstack/react-query"
import { userTaleKey } from "./TaleQueries"
import { useUserToken } from "../token/TokenQuery"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useState } from "react"
import { IntegrateUpdatedTale } from "./TaleIntegrations"
import { ProposalResource, TaleResource } from "../../generated/BackendApiClient"
import { useUserSettingsQuery } from "../user/settings/UserSettingsQueries"
import { invalidateCoins } from "../user/resources/UserResourceMutators"

export const useCreateTaleMutator = () => {

    const queryClient = useQueryClient()
    const { userSettings } = useUserSettingsQuery()
    const { userToken } = useUserToken()
    const [creating, setCreating] = useState(false)

    const createTaleMutator = useMutation({
        mutationFn: async (proposal: ProposalResource) => {
            setCreating(true)
            const response = await GetBackendClient(userToken, userSettings).createTale.createTale(proposal)
            invalidateCoins(queryClient)
            setCreating(false)
            return response.data
        },
        onSuccess: (newTale) => {
            IntegrateUpdatedTale(queryClient)(newTale)
            queryClient.invalidateQueries({ queryKey: [userTaleKey] })
        }
    })

    return { createTaleMutator, creatingTale: creating }
}

type UpdateTale = { tale: TaleResource }
export const useUpdateTaleMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [updating, setUpdating] = useState(false)

    const updateTaleMutator = useMutation({
        mutationFn: async (updateTale: UpdateTale) => {
            setUpdating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).updateTale.updateTale(updateTale.tale.id.id, updateTale.tale)
            setUpdating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { updateTaleMutator, updatingTale: updating }
}