import { FC, useState } from "react"
import { TaleActView } from "./TaleActView"
import { Button, Spinner } from "react-bootstrap"
import { useCreateActsMutator, useUpdateCampaignMutator } from "../../stores/tale/campaign/CampaignMutators"
import { CampaignResource, TaleIdResource } from "../../generated/BackendApiClient"
import { CostingButton } from "../CostingButton"
import { HasTalePermission } from "../../util/Permission"

interface TaleCampaignViewProps {
    campaign: CampaignResource,
    taleId: TaleIdResource
}

export const TaleCampaignView: FC<TaleCampaignViewProps> = ({ campaign, taleId }) => {

    const { createActsMutator, creatingActs } = useCreateActsMutator()
    const { updateCampaignMutator, updatingCampaign } = useUpdateCampaignMutator()

    const [editing, setEditing] = useState<boolean>(false)

    const createActs = async () => createActsMutator.mutate({ campaignId: campaign.id, taleId: taleId })

    const saveOrEditCampaign = async () => {
        if (editing) {
            await updateCampaignMutator.mutateAsync({taleId: taleId, campaign: campaign})
        } 

        setEditing(!editing)
    }

    return (
        <div id={'campaign_' + campaign.story.name}>
            <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "16px" }}>
                <h2>{campaign.story.name}</h2>
                <p style={{ marginBottom: "8px", marginLeft: "12px" }}>Campaign</p>
            </div>
            <div onBlur={(event) => campaign.story.description = event.currentTarget.innerText} suppressContentEditableWarning={true}
                contentEditable={editing}>
                {campaign.story.description}
            </div>
            {HasTalePermission(taleId, "EditTale") ? <Button disabled={updatingCampaign} onClick={saveOrEditCampaign}>{editing ? "Save" : "Edit Campaign"}</Button> : <></>}
            {updatingCampaign && <Spinner/>}
            <h2 style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop:40 }}>Acts</h2>
            {campaign.acts.map(act => <TaleActView key={act.actSummary.name} act={act} campaignId={campaign.id} taleId={taleId} />)}
            {creatingActs ? <div>Creating Acts... <Spinner /></div> : <></>}
            {!creatingActs && HasTalePermission(taleId, "EditTale") && campaign.acts.length === 0 ? 
                <CostingButton 
                    style={{margin: "12px 0px 12px 0px"}}
                    onClick={createActs}
                    text={`Create Acts for Campaign ${campaign.story.name}`}
                    costSelector={c => c?.campaignCreation} 
                /> : 
                <></>
            }
        </div>
    )
}