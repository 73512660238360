import { useQueryClient, useQuery } from "@tanstack/react-query";
import { AuthenticationResponseResource } from "../../generated/BackendApiClient";
import { GetBackendClient } from "../../services/BackendClientFactory";
import { userAuthResourceKey, userTokenKey } from "./TokenQuery";

export const googleTokenKey = "googleToken"

export const useGoogleAuthQuery = () => {
    const queryClient = useQueryClient()

    const { data: googleToken } = useQuery({
        queryKey: [googleTokenKey],
        queryFn: async () => {
            const token = queryClient.getQueryData([googleTokenKey]);
            return token as string ?? null
        }
    })

    useQuery<AuthenticationResponseResource>({
        queryKey: [userTokenKey, googleTokenKey, googleToken],
        queryFn: async () => {
            const resp = await GetBackendClient(undefined).googleAuthentication.googleAuthentication(googleToken as string)
            queryClient.setQueryData([userAuthResourceKey], resp.data)
            queryClient.invalidateQueries({ queryKey: [userTokenKey] })
            return resp.data
        },
        enabled: !!googleToken
    })

    return { googleToken: googleToken }
}