import { faRefresh, faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useRef, useEffect } from "react"
import { Spinner, Button, Table } from "react-bootstrap"
import { useConfirmApplicationMutator, useRefreshBetaInfoMutator, useUpdateCeilingMutator } from "../../stores/beta/BetaMutators"
import { useBetaInfoQuery } from "../../stores/beta/BetaQueries"
import './BetaOverviewComponent.css'

export const BetaOverviewComponent: FC = () => {
    const { info } = useBetaInfoQuery()
    const { confirmApplicationMutator, confirming } = useConfirmApplicationMutator()
    const { refreshBetaInfoMutator } = useRefreshBetaInfoMutator()
    const { ceilingMutator, updating } = useUpdateCeilingMutator()
    const input = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (input.current && info) {
            input.current.value = info.betaUserCeiling.toString()
        }
    }, [info, info?.betaUserCeiling])

    if (info === undefined) {
        return <Spinner />
    }

    return (
        <div className="beta-component">
            <h3>Beta Queue <Button onClick={() => refreshBetaInfoMutator.mutate()}>Refresh All <FontAwesomeIcon icon={faRefresh} /></Button></h3>
            <span className="amountInfo">
                BetaUsers (Current/Max): {info.betaUserCount}/
                <input type="number" ref={input} defaultValue={info.betaUserCeiling} />
                <Button className="update-ceil" onClick={() => { if (input.current) ceilingMutator.mutate(Number(input.current.value)) }}>UpdateCeiling</Button>{updating && <Spinner />}
            </span>
            <Table>
                <thead>
                    <tr>
                        <th>UserId</th>
                        <th>Email</th>
                        <th>Application Date</th>
                        <th>Confirm</th>
                    </tr>
                </thead>

                <tbody>
                    {info.applications.map(app => (
                        <tr key={app.userId}>
                            <td>{app.userId}</td>
                            <td>{app.email}</td>
                            <td>{new Date(app.application).toLocaleString()}</td>
                            <td><Button onClick={() => confirmApplicationMutator.mutateAsync(app.userId)}><FontAwesomeIcon icon={faCheck} /></Button></td>
                        </tr>
                    ))}
                    {info.applications.length === 0 && <tr><td colSpan={4}>No applications</td></tr>}
                </tbody>
            </Table>
            {confirming && <Spinner />}
        </div>
    )
}