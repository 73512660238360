import { useQuery } from "@tanstack/react-query"
import { CharacterResource, TaleIdResource } from "../../generated/BackendApiClient"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { taleKey } from "../tale/TaleQueries"
import { useUserToken } from "../token/TokenQuery"

export const rulesetExteionsionsKey = "rulesetExteionsions"

export const useRulesetExteionsionsByTaleIdQuery = (taleId: TaleIdResource) => {

    const { userToken, isAuthenticated } = useUserToken()

    const { data: rulesetExteionsions, isLoading } = useQuery({
        queryKey: [taleKey, taleId.id, rulesetExteionsionsKey],
        queryFn: async () => {
            const resp = await GetBackendClient(userToken).getRulesetExtensions.getRulesetExtensions(taleId.id)
            return resp.data
        },
        enabled: isAuthenticated
    })

    return { rulesetExteionsions, isLoading }
}

export const useCharacterStatsQuery = (taleId: TaleIdResource, character: CharacterResource) => {

    const { rulesetExteionsions, isLoading } = useRulesetExteionsionsByTaleIdQuery(taleId);

    return { characterStats: rulesetExteionsions?.characterStats.find(stat => stat.characterId.identifier === character.id.identifier) ?? null, isLoading }
}