import { FC } from "react";
import { TaleHtml } from "../components/TaleHtml";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useTalesByIdQuery } from "../stores/tale/TaleQueries";

export const TalePage: FC = () => {

    const { taleId } = useParams();
    const { tale } = useTalesByIdQuery({ id: taleId ?? '' })

    if (tale === undefined) {
        return <div>Loading Tale... <Spinner /></div>
    }

    return (
        <div className='mainContainer'>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                <TaleHtml tale={tale}></TaleHtml>
            </div>
        </div>
    )
}
