import { useState } from "react"
import { useUserToken } from "../token/TokenQuery"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { FeatureFlag } from "../../generated/BackendApiClient"
import { featureFlagQueryKey } from "./FeatureFlagQueries"

export const useFeatureFlagMutator = () => {

    const { userToken } = useUserToken()
    const [updating, setUpdating] = useState(false)
    const queryClient = useQueryClient()

    const featureFlagMutator = useMutation({
        mutationFn: async (updatedFlag: FeatureFlag) => {
            setUpdating(true)
            const updatedFlags = await GetBackendClient(userToken).featureFlags
                .setFeatureFlag({ name: updatedFlag.name, active: updatedFlag.active.toString() })
            setUpdating(false)
            return updatedFlags.data
        },
        onMutate: (updatedFlag: FeatureFlag) => {
            let oldFlags: FeatureFlag[] | undefined
            queryClient.setQueryData([featureFlagQueryKey], (old: FeatureFlag[] | undefined) => {
                oldFlags = old
                if (old) {
                    return old.map(f => f.name === updatedFlag.name ? updatedFlag : f)
                }
                return old
            })
            return oldFlags
        },
        onError: (_err, _updatedFlag, oldFlags) => {
            queryClient.setQueryData([featureFlagQueryKey], oldFlags)
        },
        onSuccess: (data) => {
            queryClient.setQueryData([featureFlagQueryKey], data)
        }
    })

    return { featureFlagMutator, updatingFlag: updating }
}
