/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActResource {
  id: IdResource;
  actSummary: ActSummaryResource;
  encounters: EncounterResource[];
}

export interface ActSummaryResource {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
}

export interface AuthenticationResponseResource {
  errorMessage?: string;
  userToken?: string;
  userSettingsResource?: UserSettingsResource;
}

export interface BetaApplicationResource {
  /** @minLength 1 */
  id: string;
  /**
   * @format date-time
   * @minLength 1
   */
  application: string;
  /** @minLength 1 */
  userId: string;
  /** @minLength 1 */
  email: string;
}

export interface BetaInfoResource {
  /** @format int32 */
  betaUserCeiling: number;
  /** @format int32 */
  betaUserCount: number;
  applications: BetaApplicationResource[];
}

export interface CampaignResource {
  id: IdResource;
  story: StoryResource;
  acts: ActResource[];
}

export interface CharacterResource {
  id: IdResource;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  visualDescription: string;
  /** @minLength 1 */
  homeRegionName: string;
  /** @minLength 1 */
  summary: string;
  /** @minLength 1 */
  gender: string;
  /** @minLength 1 */
  species: string;
}

export interface CharacterStatsResource {
  characterId: IdResource;
  /** @format int32 */
  health: number;
  /** @format int32 */
  armorClass: number;
  /** @format int32 */
  wisdom: number;
  /** @format int32 */
  intelligence: number;
  /** @format int32 */
  strength: number;
  /** @format int32 */
  dexterity: number;
}

export interface CoinCostResource {
  /** @format int32 */
  proposalGeneration: number;
  /** @format int32 */
  taleCreation: number;
  /** @format int32 */
  regionCreation: number;
  /** @format int32 */
  characterCreation: number;
  /** @format int32 */
  speciesCreation: number;
  /** @format int32 */
  imageCreation: number;
  /** @format int32 */
  campaignCreation: number;
  /** @format int32 */
  actCreation: number;
  /** @format int32 */
  itemCreation: number;
  /** @format int32 */
  encounterCreation: number;
}

export interface EncounterResource {
  id: IdResource;
  /** @minLength 1 */
  type: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
  quest: QuestResource;
  location: LocationResource;
  enemies?: EnemyResource[];
}

export interface EnemyResource {
  /** @minLength 1 */
  species: string;
}

export interface FeatureFlag {
  /** @minLength 1 */
  name: string;
  active: boolean;
}

export interface IdResource {
  /** @minLength 1 */
  identifier: string;
}

export interface ImageResource {
  /** @minLength 1 */
  base64EncodedData: string;
  /** @minLength 1 */
  altText: string;
}

export interface ItemResource {
  id: IdResource;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  visualDescription: string;
  /** @minLength 1 */
  summary: string;
}

export interface LocationResource {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  visualDescription: string;
}

export interface OpenAiApiLlmUsage {
  /** @minLength 1 */
  id: string;
  /**
   * @format date-time
   * @minLength 1
   */
  timeStamp: string;
  /** @minLength 1 */
  v: string;
  /** @minLength 1 */
  functionName: string;
  /** @minLength 1 */
  functionId: string;
  /** @minLength 1 */
  model: string;
  /** @format int32 */
  completionTokens: number;
  /** @format int32 */
  promptTokens: number;
  /**
   * @format timespan
   * @minLength 1
   */
  processingTime: string;
  /** @format double */
  costsInDollar?: number | null;
}

export interface ProposalResource {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  inOneSentence: string;
  /** @minLength 1 */
  gist: string;
}

export interface QuestResource {
  /** @minLength 1 */
  goal: string;
  /** @minLength 1 */
  reward: string;
}

export interface RegionResource {
  id: IdResource;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  visualDescription: string;
}

export interface SetImageResource {
  /** @minLength 1 */
  imageBase64Data: string;
  /** @minLength 1 */
  visualDescription: string;
}

export interface SpeciesResource {
  id: IdResource;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
  /** @minLength 1 */
  visualDescription: string;
  /** @minLength 1 */
  summary: string;
}

export interface StoryResource {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
}

export interface TaleIdResource {
  /** @minLength 1 */
  id: string;
}

export interface TalePermissionResource {
  id: TaleIdResource;
  permissions: string[];
}

export interface TaleResource {
  id: TaleIdResource;
  world: WorldResource;
  regions: RegionResource[];
  characters: CharacterResource[];
  campaigns: CampaignResource[];
  species: SpeciesResource[];
  items: ItemResource[];
}

export interface TaleRulesetExtensionsResource {
  taleId: TaleIdResource;
  characterStats: CharacterStatsResource[];
}

export interface UserResourcesResource {
  /** @format int32 */
  coins: number;
}

export interface UserSettingsResource {
  /** @minLength 1 */
  preferredImageStyle: string;
  /** @minLength 1 */
  openAiModelType: string;
  /** @minLength 1 */
  userName: string;
}

export interface UserTalePermissionResource {
  talePermissions: TalePermissionResource[];
}

export interface WorldAttributesResource {
  /** @minLength 1 */
  geography: string;
  /** @minLength 1 */
  cultureAndCivilizations: string;
  /** @minLength 1 */
  mythologyAndMagic: string;
  /** @minLength 1 */
  technologyLevel: string;
  /** @minLength 1 */
  historyAndBackground: string;
  /** @minLength 1 */
  economy: string;
}

export interface WorldResource {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  description: string;
  attributes: WorldAttributesResource;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title No title
 * @version 1.0.0
 * @baseUrl /api
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  getBetaInfo = {
    /**
     * No description
     *
     * @tags Beta
     * @name GetBetaInfo
     * @summary Gets Beta-Info
     * @request GET:/GetBetaInfo
     */
    getBetaInfo: (params: RequestParams = {}) =>
      this.request<BetaInfoResource, any>({
        path: `/GetBetaInfo`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  confirmBetaApplication = {
    /**
     * No description
     *
     * @tags Beta
     * @name ConfirmBetaApplication
     * @summary Confirms a beta-application
     * @request GET:/ConfirmBetaApplication
     */
    confirmBetaApplication: (
      query: {
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/ConfirmBetaApplication`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  betaTesterCeiling = {
    /**
     * No description
     *
     * @tags Beta
     * @name SetBetaTesterCeiling
     * @summary Sets the ceiling of Beta-Testers
     * @request GET:/BetaTesterCeiling
     */
    setBetaTesterCeiling: (
      query: {
        ceiling: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/BetaTesterCeiling`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  applyForBeta = {
    /**
     * No description
     *
     * @tags Beta
     * @name ApplyForBeta
     * @summary Puts the user on the list of Beta-Test
     * @request POST:/ApplyForBeta
     */
    applyForBeta: (
      query: {
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/ApplyForBeta`,
        method: "POST",
        query: query,
        ...params,
      }),
  };
  buyCoins = {
    /**
     * @description Creates a stripe session
     *
     * @tags coins
     * @name BuyCoins
     * @summary Buy coins
     * @request POST:/BuyCoins
     */
    buyCoins: (
      query: {
        coinAmount: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/BuyCoins`,
        method: "POST",
        query: query,
        ...params,
      }),
  };
  createActs = {
    /**
     * @description Generates all acts from the summary in the campaign.
     *
     * @tags tale
     * @name CreateActs
     * @summary Generates all acts
     * @request PUT:/CreateActs/{taleId}/{campaignId}
     */
    createActs: (taleId: string, campaignId: string, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/CreateActs/${taleId}/${campaignId}`,
        method: "PUT",
        format: "json",
        ...params,
      }),
  };
  createCampaign = {
    /**
     * @description Generates a new empty campaign that only has a name and summary.
     *
     * @tags tale
     * @name CreateCampaign
     * @summary Generates a new empty campaign
     * @request POST:/CreateCampaign/{taleId}
     */
    createCampaign: (taleId: string, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/CreateCampaign/${taleId}`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  createCharacter = {
    /**
     * @description Generates a whole new character.
     *
     * @tags tale
     * @name CreateCharacter
     * @summary Generates a new character
     * @request POST:/CreateCharacter/{taleId}
     */
    createCharacter: (taleId: string, data: ProposalResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/CreateCharacter/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  createEncounter = {
    /**
     * @description Generates an Encounter of a specific type for a given act of an campaign.
     *
     * @tags encounter
     * @name CreateEncounter
     * @summary Generates an Encounter of a specific type
     * @request PUT:/CreateEncounter/{taleId}/{campaignId}/{actId}/{type}
     */
    createEncounter: (
      taleId: string,
      campaignId: string,
      actId: string,
      type: string,
      data: ProposalResource,
      params: RequestParams = {},
    ) =>
      this.request<TaleResource, any>({
        path: `/CreateEncounter/${taleId}/${campaignId}/${actId}/${type}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  createImage = {
    /**
     * @description Generates a new image from the given visual description.
     *
     * @tags visualization
     * @name CreateImage
     * @summary Generates a new image
     * @request POST:/CreateImage/{taleId}
     */
    createImage: (taleId: string, data: string, params: RequestParams = {}) =>
      this.request<ImageResource, any>({
        path: `/CreateImage/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  createItem = {
    /**
     * @description Generates a whole new item.
     *
     * @tags tale
     * @name CreateItem
     * @summary Generates a new item
     * @request POST:/CreateItem/{taleId}
     */
    createItem: (taleId: string, data: ProposalResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/CreateItem/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  tales = {
    /**
     * @description Generates all regions from the summaries provided in the world.
     *
     * @tags tale
     * @name CreateRegions
     * @summary Generates missing regions
     * @request POST:/Tales/{taleId}/Regions
     */
    createRegions: (taleId: string, data: ProposalResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/Tales/${taleId}/Regions`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  createSpecies = {
    /**
     * @description Generates a whole new species.
     *
     * @tags tale
     * @name CreateSpecies
     * @summary Generates a new species
     * @request POST:/CreateSpecies/{taleId}
     */
    createSpecies: (taleId: string, data: ProposalResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/CreateSpecies/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  createTale = {
    /**
     * No description
     *
     * @tags tale
     * @name CreateTale
     * @summary Creates a new empty tale
     * @request POST:/CreateTale
     */
    createTale: (data: ProposalResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/CreateTale`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  featureFlags = {
    /**
     * No description
     *
     * @tags FeatureFlags
     * @name FeatureFlags
     * @summary Gets all feature flags
     * @request GET:/FeatureFlags
     */
    featureFlags: (params: RequestParams = {}) =>
      this.request<FeatureFlag[], any>({
        path: `/FeatureFlags`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FeatureFlags
     * @name SetFeatureFlag
     * @summary Sets the value of a feature flags
     * @request PATCH:/FeatureFlags
     */
    setFeatureFlag: (
      query: {
        name: string;
        active: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FeatureFlag[], any>({
        path: `/FeatureFlags`,
        method: "PATCH",
        query: query,
        format: "json",
        ...params,
      }),
  };
  getCosts = {
    /**
     * @description Returns an object containing all the costs.
     *
     * @tags coins
     * @name GetCosts
     * @summary Gets the costs
     * @request GET:/GetCosts
     */
    getCosts: (params: RequestParams = {}) =>
      this.request<CoinCostResource, any>({
        path: `/GetCosts`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getImage = {
    /**
     * @description Returns the image if it exists else returns 404 NotFound.
     *
     * @tags visualization
     * @name GetImage
     * @summary Gets an image for the given visual description
     * @request POST:/GetImage/{taleId}
     */
    getImage: (taleId: string, data: string, params: RequestParams = {}) =>
      this.request<ImageResource, void>({
        path: `/GetImage/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  getTaleJson = {
    /**
     * No description
     *
     * @tags tale
     * @name GetTaleJson
     * @summary Gets a tale by its Id
     * @request GET:/GetTaleJson/{id}
     */
    getTaleJson: (id: string, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/GetTaleJson/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getTalesOfUser = {
    /**
     * No description
     *
     * @tags tale
     * @name GetTalesOfUser
     * @summary Gets a list of taleIds that the user can access
     * @request GET:/GetTalesOfUser
     */
    getTalesOfUser: (params: RequestParams = {}) =>
      this.request<UserTalePermissionResource, any>({
        path: `/GetTalesOfUser`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getUserResources = {
    /**
     * No description
     *
     * @tags userResources
     * @name GetUserResources
     * @summary Gets the Users current Resources
     * @request GET:/GetUserResources
     */
    getUserResources: (params: RequestParams = {}) =>
      this.request<UserResourcesResource, any>({
        path: `/GetUserResources`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  getUserSettings = {
    /**
     * No description
     *
     * @tags userSettings
     * @name GetUserSettings
     * @summary Gets the Users current Settings
     * @request GET:/GetUserSettings
     */
    getUserSettings: (params: RequestParams = {}) =>
      this.request<UserSettingsResource, any>({
        path: `/GetUserSettings`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  googleAuthentication = {
    /**
     * No description
     *
     * @tags user
     * @name GoogleAuthentication
     * @summary Authenticates the user by google-token
     * @request POST:/GoogleAuthentication
     */
    googleAuthentication: (data: string, params: RequestParams = {}) =>
      this.request<AuthenticationResponseResource, any>({
        path: `/GoogleAuthentication`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  microsoftAuthentication = {
    /**
     * No description
     *
     * @tags user
     * @name MicrosoftAuthentication
     * @summary Authenticates the user by microsoft-token
     * @request POST:/MicrosoftAuthentication
     */
    microsoftAuthentication: (data: string, params: RequestParams = {}) =>
      this.request<AuthenticationResponseResource, any>({
        path: `/MicrosoftAuthentication`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  proposals = {
    /**
     * No description
     *
     * @tags proposals
     * @name CreateTaleProposals
     * @summary Get three tale proposals
     * @request POST:/Proposals/Tale
     */
    createTaleProposals: (data: string, params: RequestParams = {}) =>
      this.request<ProposalResource[], any>({
        path: `/Proposals/Tale`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags proposals
     * @name CreateRegionProposals
     * @summary Get three region proposals
     * @request POST:/Proposals/{taleId}/Region
     */
    createRegionProposals: (taleId: string, data: string, params: RequestParams = {}) =>
      this.request<ProposalResource[], any>({
        path: `/Proposals/${taleId}/Region`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags proposals
     * @name CreateCharacterProposals
     * @summary Get three character proposals
     * @request POST:/Proposals/{taleId}/Character
     */
    createCharacterProposals: (taleId: string, data: string, params: RequestParams = {}) =>
      this.request<ProposalResource[], any>({
        path: `/Proposals/${taleId}/Character`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags proposals
     * @name CreateSpeciesProposals
     * @summary Get three species proposals
     * @request POST:/Proposals/{taleId}/Species
     */
    createSpeciesProposals: (taleId: string, data: string, params: RequestParams = {}) =>
      this.request<ProposalResource[], any>({
        path: `/Proposals/${taleId}/Species`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags proposals
     * @name CreateItemProposals
     * @summary Get three item proposals
     * @request POST:/Proposals/{taleId}/Item
     */
    createItemProposals: (taleId: string, data: string, params: RequestParams = {}) =>
      this.request<ProposalResource[], any>({
        path: `/Proposals/${taleId}/Item`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags proposals
     * @name CreateEncounterProposals
     * @summary Get three encounter proposals
     * @request POST:/Proposals/{taleId}/Encounter
     */
    createEncounterProposals: (
      taleId: string,
      query: {
        /** The name of the campaign within the tale */
        campaignId: string;
        /** The name of the act within the campaign */
        actId: string;
        /** The type of the encounter */
        encounterType?: string;
      },
      data: string,
      params: RequestParams = {},
    ) =>
      this.request<ProposalResource[], any>({
        path: `/Proposals/${taleId}/Encounter`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  getRulesetExtensions = {
    /**
     * No description
     *
     * @tags Ruleset
     * @name GetRulesetExtensions
     * @summary Gets the RulesetExtensions
     * @request GET:/GetRulesetExtensions/{id}
     */
    getRulesetExtensions: (id: string, params: RequestParams = {}) =>
      this.request<TaleRulesetExtensionsResource, any>({
        path: `/GetRulesetExtensions/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  rulesetExtensions = {
    /**
     * No description
     *
     * @tags Ruleset
     * @name SetCharacterStats
     * @summary ets or updates CharacterStats
     * @request POST:/RulesetExtensions/{id}/CharacterStats
     */
    setCharacterStats: (id: string, data: CharacterStatsResource, params: RequestParams = {}) =>
      this.request<TaleRulesetExtensionsResource, any>({
        path: `/RulesetExtensions/${id}/CharacterStats`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  setImage = {
    /**
     * @description Set a new image from the given visual description.
     *
     * @tags visualization
     * @name SetImage
     * @summary Sets a new image
     * @request POST:/SetImage/{taleId}
     */
    setImage: (taleId: string, data: SetImageResource, params: RequestParams = {}) =>
      this.request<ImageResource, any>({
        path: `/SetImage/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  spawnCoins = {
    /**
     * No description
     *
     * @tags userResources
     * @name SpawnCoins
     * @summary Gives Coins to the user for free
     * @request GET:/SpawnCoins
     */
    spawnCoins: (
      query: {
        coinAmount: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserResourcesResource, any>({
        path: `/SpawnCoins`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  updateTale = {
    /**
     * No description
     *
     * @tags tale
     * @name UpdateTale
     * @summary Replaces a tale
     * @request POST:/UpdateTale/{taleId}
     */
    updateTale: (taleId: string, data: TaleResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/UpdateTale/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  updateCharacter = {
    /**
     * No description
     *
     * @tags character
     * @name UpdateCharacter
     * @summary Replaces a character in a tale
     * @request POST:/UpdateCharacter/{taleId}
     */
    updateCharacter: (taleId: string, data: CharacterResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/UpdateCharacter/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  updateRegion = {
    /**
     * No description
     *
     * @tags region
     * @name UpdateRegion
     * @summary Replaces a region in a tale
     * @request POST:/UpdateRegion/{taleId}
     */
    updateRegion: (taleId: string, data: RegionResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/UpdateRegion/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  updateCampaign = {
    /**
     * No description
     *
     * @tags region
     * @name UpdateCampaign
     * @summary Replaces a campaign in a tale
     * @request POST:/UpdateCampaign/{taleId}
     */
    updateCampaign: (taleId: string, data: CampaignResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/UpdateCampaign/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  updateAct = {
    /**
     * No description
     *
     * @tags act
     * @name UpdateAct
     * @summary Replaces a act in a tale
     * @request POST:/UpdateAct/{taleId}/{campaignId}
     */
    updateAct: (taleId: string, campaignId: string, data: ActResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/UpdateAct/${taleId}/${campaignId}`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  updateEncounter = {
    /**
     * No description
     *
     * @tags encounter
     * @name UpdateEncounter
     * @summary Replaces an encounter in a tale
     * @request POST:/UpdateEncounter/{taleId}/{campaignId}/{actId}
     */
    updateEncounter: (
      taleId: string,
      campaignId: string,
      actId: string,
      data: EncounterResource,
      params: RequestParams = {},
    ) =>
      this.request<TaleResource, any>({
        path: `/UpdateEncounter/${taleId}/${campaignId}/${actId}`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  updateItem = {
    /**
     * No description
     *
     * @tags item
     * @name UpdateItem
     * @summary Replaces an item in a tale
     * @request POST:/UpdateItem/{taleId}
     */
    updateItem: (taleId: string, data: ItemResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/UpdateItem/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  updateSpecies = {
    /**
     * No description
     *
     * @tags species
     * @name UpdateSpecies
     * @summary Replaces a species in a tale
     * @request POST:/UpdateSpecies/{taleId}
     */
    updateSpecies: (taleId: string, data: SpeciesResource, params: RequestParams = {}) =>
      this.request<TaleResource, any>({
        path: `/UpdateSpecies/${taleId}`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
  telemetry = {
    /**
     * @description Returns a list of objects containing all LLMCalls of that day.
     *
     * @tags Telemetry
     * @name GetLlmCosts
     * @summary Get LLM Costs of a day
     * @request GET:/Telemetry/LlmCosts/{day}
     */
    getLlmCosts: (day: string, params: RequestParams = {}) =>
      this.request<OpenAiApiLlmUsage[], any>({
        path: `/Telemetry/LlmCosts/${day}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  updateSettings = {
    /**
     * @description Updates the settings of the user.
     *
     * @tags settings
     * @name UpdateSettings
     * @summary Updates the settings of the user
     * @request POST:/UpdateSettings
     */
    updateSettings: (data: UserSettingsResource, params: RequestParams = {}) =>
      this.request<UserSettingsResource, any>({
        path: `/UpdateSettings`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
