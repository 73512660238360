import { FC } from "react"
import './Town.css'
import '../Login/SpeechBubbles.scss'
import { useUserSettingsQuery } from "../../stores/user/settings/UserSettingsQueries"
import { Link } from "react-router-dom"

export const Town: FC = () => {
    const { userSettings } = useUserSettingsQuery();

    const preLoadSources = ['/img/library.png', '/img/forge.png', '/img/tavern.png', '/img/store.png']

    return (
        <div className="content">
            {preLoadSources.map(src => (
                <img src={src} key={src} alt="preloaded" style={{ display: "none" }} />
            ))}
            <div className='mainContainer'>
                <img
                    src={process.env.PUBLIC_URL + '/img/city.png'}
                    className='town-img'
                    alt='Town'
                />
                <Link to="/Forge">
                    <img
                        src={process.env.PUBLIC_URL + '/img/smith.png'}
                        className='smith-img zoom'
                        alt='Smith'
                    />
                    <div className="smith-bubble town-bubble bubble grow bottom guard-bubble">
                        You look like you need some equipment.
                    </div>
                </Link>
                <Link to="/Market">
                    <img
                        src={process.env.PUBLIC_URL + '/img/trader.png'}
                        className='trader-img zoom'
                        alt='Trader'
                    />
                    <div className="trader-bubble town-bubble bubble grow bottom guard-bubble">
                        You, adventurer! Come, I have whatever your heart desires.
                    </div>
                </Link>
                <Link to="/Tavern">
                    <img
                        src={process.env.PUBLIC_URL + '/img/barkeeper.png'}
                        className='barkeeper-img zoom'
                        alt='Barkeeper'
                    />
                    <div className="barkeeper-bubble town-bubble bubble grow right guard-bubble">
                        Let's grab a drink and talk to the other adventurer's!
                    </div>
                </Link>
                <Link to="/Library">
                    <img
                        src={process.env.PUBLIC_URL + '/img/bard.png'}
                        className='bard-img zoom'
                        alt='Bard'
                    />
                    <div className="bard-bubble town-bubble bubble grow right guard-bubble">
                        ... our champion {userSettings?.userName} prevails, read their epic tales.
                    </div>
                </Link>
                <Link to="/MageTower">
                    <img
                        src={process.env.PUBLIC_URL + '/img/mage.png'}
                        className='mage-img zoom'
                        alt='Mage'
                    />
                    <div className="mage-bubble town-bubble bubble grow left guard-bubble">
                        Are you satisfied with your current state of existence?<br />If not, I might just have the spell for that...
                    </div>
                </Link>
            </div>
        </div>
    )
}