import { FC, useRef, useState } from "react";
import { User } from "../../util/TokenDecoder";
import { Button, Card, Spinner } from "react-bootstrap";
import { useSendBetaEnlistRequestMutator } from "../../stores/user/BetaAccessMutator";
import './RequestBetaAccess.css';
import { useLogoutMutator } from "../../stores/token/TokenMutators";
import { useUserSettingsQuery } from "../../stores/user/settings/UserSettingsQueries";

export const RequestBetaAcces: FC<{ user: User }> = ({ user }) => {

    const { userSettings } = useUserSettingsQuery()
    const { sendBetaEnlistRequestMutator, sending } = useSendBetaEnlistRequestMutator()
    const { logoutMutation } = useLogoutMutator()
    const userEmailInput = useRef<HTMLInputElement>(null)
    const [hasSent, setHasSent] = useState(false)

    const sendRequest: React.FormEventHandler<HTMLFormElement> = async e => {
        e.preventDefault()

        if (!userEmailInput.current) {
            return
        }

        await sendBetaEnlistRequestMutator.mutateAsync(userEmailInput.current.value)
        setHasSent(true)
    }

    return (
        <div>
            <img
                src={process.env.PUBLIC_URL + '/img/queue.png'}
                className='queue-img'
                alt='Queue'
            />
            <img
                src={process.env.PUBLIC_URL + '/img/guard.png'}
                className='queue-guard-img'
                alt='Guard'
            />
            <div className='text-frame'>
                <Card>
                    <Card.Header><h3>Welcome {userSettings?.userName}!</h3></Card.Header>
                    <Card.Body>
                        <p className = 'info-text' style={{ textAlign: "justify" }}>
                            Listen! You are not currently a Beta-tester, so you can't access this area yet. If you'd like to apply, you can request access for free here.
                            Your email will only be used to notify you once your request is approved.
                            It will be deleted immediately afterwards, and we won't send you any other messages.<br />
                        </p>
                        <div style={{ marginBlock: 10 }}>
                            <form onSubmit={sendRequest}>
                                <input id = "e-mail-input" disabled={hasSent} ref={userEmailInput} type="email" name="email" placeholder="E-Mail" required />
                                <Button disabled={hasSent} style={{ marginLeft: 10 }} className="slim-btn" type="submit">Apply for Beta</Button>
                            </form>
                            {hasSent && <p>You have sucessfully applied for the beta.</p>}
                            {sending && <Spinner />}
                        </div>
                        <div>
                            <p className = 'info-text'> 
                                If your access has been confirmed and you still see this screen click 
                            </p>
                            <Button id = "click-here-btn" className="slim-btn" onClick={() => logoutMutation.mutate()}>Here</Button>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}