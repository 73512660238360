import { Api, UserSettingsResource } from "../generated/BackendApiClient"
import { API_BASEURL } from "../util/Envs"

export const GetBackendClient = (token: string | undefined, userSettings?: UserSettingsResource) : Api<unknown> => {
    
    const authHeaders = token ? { Authorization: `Bearer ${token}` } : {}
    const settingsHeaders = userSettings ? { gptModel: userSettings.openAiModelType } : {}
    const headers = {...authHeaders, ...settingsHeaders}

    return new Api({
        baseUrl: API_BASEURL,
        baseApiParams: {
            credentials: "include",
            redirect: "error",
            referrerPolicy: "strict-origin",
            headers: headers as any
        }
    })
}