import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { GetBackendClient } from "../../../services/BackendClientFactory"
import { useUserToken } from "../../token/TokenQuery"
import { IntegrateUpdatedTale } from "../TaleIntegrations"
import { useQueryClient } from '@tanstack/react-query'
import { ProposalResource, RegionResource, TaleIdResource } from "../../../generated/BackendApiClient"
import { useUserSettingsQuery } from "../../user/settings/UserSettingsQueries"
import { invalidateCoins } from "../../user/resources/UserResourceMutators"

type RegionCreationArgs = {
    taleId: TaleIdResource,
    proposal: ProposalResource
}

export const useCreateRegionsMutator = () => {

    const { userToken } = useUserToken()
    const [creating, setCreating] = useState(false)
    const { userSettings } = useUserSettingsQuery()
    const queryClient = useQueryClient()

    const createRegionsMutator = useMutation({
        mutationFn: async (creationArgs : RegionCreationArgs) => {
            setCreating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).tales.createRegions(creationArgs.taleId.id, creationArgs.proposal)
            invalidateCoins(queryClient)
            setCreating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { createRegionsMutator, creatingRegions: creating }
}

type UpdateRegion = { taleId: TaleIdResource, region: RegionResource }
export const useUpdateRegionsMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [updating, setUpdating] = useState(false)

    const updateRegionsMutator = useMutation({
        mutationFn: async (updateRegion: UpdateRegion) => {
            setUpdating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).updateRegion.updateRegion(updateRegion.taleId.id, updateRegion.region)
            setUpdating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { updateRegionsMutator, updatingRegions: updating }
}