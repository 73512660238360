import { FC } from "react"
import './Admin.css'
import { FeatureFlagsComponent } from "../../components/Administration/FeatureFlagsComponent";
import { LlmUsageComponent } from "../../components/Administration/LlmUsageComponent";
import { BetaOverviewComponent } from "../../components/Administration/BetaOverviewComponent";

export const Admin: FC = () => {

    return (
        <div className="admin-page">
            <BetaOverviewComponent />
            <LlmUsageComponent />
            <FeatureFlagsComponent />
        </div>

    )
}