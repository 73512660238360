import { useQuery } from "@tanstack/react-query"
import { useUserToken } from "../token/TokenQuery"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useUserSettingsQuery } from "../user/settings/UserSettingsQueries"

export const costsQueryKey = "costs"


export const useCostsQuery = () => {

    const { userSettings } = useUserSettingsQuery()
    const { userToken, isAuthenticated } = useUserToken()
    
    const { data } = useQuery({
        queryKey: [costsQueryKey],
        enabled: isAuthenticated && !!userSettings,
        queryFn: async () => {
            const resp = await GetBackendClient(userToken, userSettings).getCosts.getCosts()
            
            return resp.data
        }
    })

    return { costs: data }
}