import { useQueryClient, useMutation } from "@tanstack/react-query"
import { useState } from "react"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useUserToken } from "../token/TokenQuery"
import { CharacterStatsResource, TaleIdResource } from "../../generated/BackendApiClient"
import { IntegrateRulesetExtensions } from "./RulesetExtensionsIntegration"

type updateChracterStatsPayload = {
    updatedResource: CharacterStatsResource,
    taleId: TaleIdResource
}
export const useUpdateCharacterStatsMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const [updating, setUpdating] = useState(false)

    const updateCharacterStatsMutator = useMutation({
        mutationFn: async (updatedPayload: updateChracterStatsPayload) => {
            setUpdating(true)
            const updatedTale = await GetBackendClient(userToken).rulesetExtensions.setCharacterStats(updatedPayload.taleId.id, updatedPayload.updatedResource)
            setUpdating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateRulesetExtensions(queryClient)
    })

    return { updateCharacterStatsMutator, updatingCharacterStats: updating }
}