import { FC, useState } from "react";
import { TaleImageFrame } from "../TaleImageFrame";
import { Button, Spinner } from "react-bootstrap";
import { useUpdateRegionsMutator } from "../../stores/tale/regions/RegionsMutators";
import { RegionResource, TaleIdResource } from "../../generated/BackendApiClient";
import { HasTalePermission } from "../../util/Permission";


interface TaleRegionViewProps {
    region: RegionResource,
    taleId: TaleIdResource
}

export const TaleRegionView: FC<TaleRegionViewProps> = ({ region, taleId }) => {

    const { updateRegionsMutator, updatingRegions } = useUpdateRegionsMutator()

    const [editing, setEditing] = useState<boolean>(false)

    const saveOrEditRegion = async () => {
        if (editing) {
            await updateRegionsMutator.mutateAsync({ region, taleId })
        } 

        setEditing(!editing)
    }

    return (
        <div id={'regions_' + region.name} style={{ display: "flex", flexDirection: "column", margin: "24px 24px 24px 0px" }}>
            <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "16px" }}>
                <h2>{region.name}</h2>
                <p style={{ marginBottom: "8px", marginLeft: "12px" }}>Region</p>
            </div>

            <div style={{ display: "flex", flexDirection: "row", position: "relative"}}>
                <div>
                    <div style={{ float: "right", height: "max-content", alignItems: "center", display: "flex", flexDirection: "column", margin: "0px 0px 12px 12px", backgroundColor: "#b93f45" }}>
                        <div style={{ margin: "8px", width: "max-content" }}>
                            <TaleImageFrame taleId={taleId} visualDescription={region.visualDescription} />
                        </div>
                    </div>
                    <div onBlur={(event) => region.description = event.currentTarget.innerText} suppressContentEditableWarning={true}
                        contentEditable={editing}>
                        {region.description}
                    </div>
                    {HasTalePermission(taleId, "EditTale") ? <Button disabled={updatingRegions} onClick={saveOrEditRegion}>{editing ? "Save" : "Edit Region"}</Button>: <></>}
                    {updatingRegions && <Spinner/>}
                </div>
            </div>
        </div>
    )
}