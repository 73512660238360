import { useMsal } from '@azure/msal-react'
import { FC } from 'react'
import { Button } from 'react-bootstrap'
import './SpeechBubbles.scss'
import './LoginForm.css'
import { AuthState } from '../../dtos/AuthState'
import { useGoogleTokenMutator, useLogoutMutator } from '../../stores/token/TokenMutators'
import { scopes } from '../../dtos/Scopes'
import { GoogleLogin } from '@react-oauth/google'

interface LoginFormProps {
  AuthState: AuthState
}

interface AuthTranslation {
  Authenticated: boolean,
  Authorized: boolean,
  Denied: boolean
}

const getAuthType = (state: AuthState): AuthTranslation => {
  return {
    Authenticated: state.isAuthenticated,
    Authorized: !!state.userToken,
    Denied: !!state.userTokenError
  }
}

const LoginForm: FC<LoginFormProps> = ({ AuthState }) => {
  const { instance } = useMsal()
  const { logoutMutation } = useLogoutMutator()
  const { googleTokenMutaton } = useGoogleTokenMutator()

  const initializeMsSignIn = () => {
    instance.loginRedirect({ scopes: scopes })
  }

  const authType = getAuthType(AuthState)

  const sholdDisplayMyBubble = !authType.Authenticated || authType.Denied

  const getGuardBubbleContent = () => {
    if (!authType.Authenticated) return <p>Hold it right there!<br />Identify your self.</p>

    if (authType.Authorized) return <p>Found you - come right in!</p>

    if (authType.Denied) return <p>Sorry I think I can't let you in. Because {AuthState.userTokenError}.</p>

    if (authType.Authenticated) return <><p>Hello, Adventurer!</p><p>I'll check if you're on the list...</p></>

    return <p>I don't know what to say...</p> //Should not be reached :).
  }

  return (
    <div className='mainContainer'>
      <img
        src={process.env.PUBLIC_URL + '/img/gate.png'}
        className='gate-img'
        alt='Gate'
      />
      <img
        src={process.env.PUBLIC_URL + '/img/guard.png'}
        className='guard-img'
        alt='Guard'
      />
      <div
        className="bubble grow left guard-bubble"
      >
        {getGuardBubbleContent()}
      </div>
      <div style={{ display: sholdDisplayMyBubble ? 'block' : 'none' }} className="bubble bottom login-bubble">
        <div className='login-bubble-container'>
          {!authType.Authenticated && <Button onClick={initializeMsSignIn}>I'm on the list from Microsoft</Button>}
          {!authType.Authenticated && <GoogleLogin onSuccess={async (res) => googleTokenMutaton.mutate(res.credential)} />}
          {authType.Denied && <Button onClick={() => logoutMutation.mutate()}>Think again!</Button>}
        </div>
      </div>
    </div>
  );
};

export default LoginForm;