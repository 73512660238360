import { FC, useState } from "react";
import { TaleImageFrame } from "../TaleImageFrame";
import { Button, Spinner } from "react-bootstrap";
import { SpeciesResource, TaleIdResource } from "../../generated/BackendApiClient";
import { useUpdateSpeciesMutator } from "../../stores/tale/species/SpeciesMutators";


interface TaleSpeciesViewProps {
    species: SpeciesResource,
    taleId: TaleIdResource
}

export const TaleSpeciesView: FC<TaleSpeciesViewProps> = ({ species, taleId }) => {

    const { updateSpeciesMutator, updatingSpecies } = useUpdateSpeciesMutator()

    const [editing, setEditing] = useState<boolean>(false)

    const saveOrEditSpecies = async () => {
        if (editing) {
            await updateSpeciesMutator.mutateAsync({ species, taleId })
        } 
        
        setEditing(!editing)
    }

    return (
        <div id={'species_' + species.name} style={{ display: "flex", flexDirection: "column", margin: "24px 24px 24px 0px" }}>
            <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "16px" }}>
                <h2>{species.name}</h2>
                <p style={{ marginBottom: "8px", marginLeft: "12px" }}>Species</p>
            </div>

            <div style={{ display: "flex", flexDirection: "row", position: "relative" }}>
                <div>
                    <div style={{ float: "left", height: "max-content", alignItems: "center", display: "flex", flexDirection: "column", margin: "0px 12px 12px 0px", backgroundColor: "#b93f45" }}>
                        <div style={{ margin: "8px", width: "max-content" }}>
                            <TaleImageFrame taleId={taleId} visualDescription={species.visualDescription} />
                        </div>
                    </div>
                    <div onBlur={(event) => species.description = event.currentTarget.innerText} suppressContentEditableWarning={true}
                        contentEditable={editing}>
                        {species.description}
                    </div>
                    <Button disabled={updatingSpecies} onClick={saveOrEditSpecies}>{editing ? "Save" : "Edit Species"}</Button>{updatingSpecies && <Spinner/>}
                </div>
            </div>
        </div>
    )
}