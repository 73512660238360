import { FC } from "react"
import './Market.css'
import { useSpawnCoinsForFreeMutator } from "../../stores/user/resources/UserResourceMutators"
import { useUserResourcesQuery } from "../../stores/user/resources/UserResourcesQueries"
import { Button, Card, CardBody, CardFooter, CardHeader, Spinner } from "react-bootstrap"
import { StripePayment } from "../../components/StripePayment"
import { useIsFeatureFlagEnabled } from "../../stores/featureflags/FeatureFlagQueries"
import { PageComponent } from "../../components/PageComponent"

export const Market: FC = () => {

    const { spawnCoinsMutator, spawning } = useSpawnCoinsForFreeMutator()
    const { userResources } = useUserResourcesQuery()
    const buyCoinsIsAvailable = useIsFeatureFlagEnabled("BuyCoins")
    const hackCoinsIsAvailable = useIsFeatureFlagEnabled("HackCoins")

    return (
        <PageComponent
            backgroundImage={process.env.PUBLIC_URL + '/img/store.png'}
            characterImage={process.env.PUBLIC_URL + '/img/trader.png'}
        >
            <>
                <Card>
                    <CardHeader><h3>You have {userResources?.coins ?? <Spinner />} Tinker Coins 🪙</h3></CardHeader>
                    <CardBody>
                        {buyCoinsIsAvailable ? <StripePayment /> : <p>Sorry but buying coins is currently not available 😥</p>}
                    </CardBody>
                    {
                        hackCoinsIsAvailable &&
                        <CardFooter>
                            <Button disabled={spawning} onClick={() => spawnCoinsMutator.mutate(100)}>Hack 100 Coins 🪙</Button>{spawning && <Spinner />}
                        </CardFooter>
                    }
                </Card>
            </>
        </PageComponent>
    )
}