import { FC, useState } from "react";
import { CoinCostResource, ProposalResource } from "../generated/BackendApiClient";
import { CostingButton } from "./CostingButton";

type ManualPromptProps = {
    onSelectedProposal: (proposal: ProposalResource) => void,
    costSelection: (costs?: CoinCostResource) => number | undefined
}

export const ManualPrompt: FC<ManualPromptProps> = ({ onSelectedProposal, costSelection }) => {

    const [proposal, setProposal] = useState<ProposalResource>({ name: "", inOneSentence: "", gist: "" })
    const [hasSelected, setHasSelected] = useState(false)

    return (
        <div style={{ marginTop: 20, display: "flex", flexDirection: "column", gap: 5, width: "100%" }}>
            <h4 style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                <CostingButton
                    onClick={() => { setHasSelected(true); onSelectedProposal(proposal) }}
                    text={"Select"}
                    costSelector={costSelection}
                    disabled={hasSelected}
                />
                <div>Manual Proposal - <input type="text" onChange={e => setProposal({ ...proposal, name: e.target.value })} value={proposal.name}></input></div>
            </h4>
            <input type="text" onChange={e => setProposal({ ...proposal, inOneSentence: e.target.value })} value={proposal.inOneSentence}></input>
            <textarea onChange={e => setProposal({ ...proposal, gist: e.target.value })} value={proposal.gist}></textarea>
        </div>
    )
}