import { FC } from 'react';
import { TaleList } from '../../components/TaleList';
import { Button, Card, CardBody, CardFooter, CardHeader, Spinner } from 'react-bootstrap';
import './Library.css';
import { useAllTalesQuery } from '../../stores/tale/TaleQueries';
import { Link } from 'react-router-dom';
import { PageComponent } from '../../components/PageComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCheck } from '@fortawesome/free-solid-svg-icons';

export const Library: FC = () => {

  const { tales, doneLoading } = useAllTalesQuery()

  return (
    <PageComponent
      backgroundImage={process.env.PUBLIC_URL + '/img/library.png'}
      characterImage={process.env.PUBLIC_URL + '/img/bard.png'}
    >
      <>
        <Card className='scrollableBody'>
          <CardHeader><h3>All your Tales <FontAwesomeIcon icon={faBook} style={{ marginLeft: 10 }} /></h3></CardHeader>
          <CardBody style={{ overflowY: 'scroll' }}>
            <TaleList tales={tales}></TaleList>
            <Link to='/CreateTale'>
              <Button style={{ marginBottom: 20 }}>Create new Tale</Button>
            </Link>
          </CardBody>
          <CardFooter>
            {!doneLoading ? <>Loading Tales...<Spinner /></> : <>Everything loaded <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 10 }} /></>}
          </CardFooter>
        </Card>
      </>
    </PageComponent>
  )
}