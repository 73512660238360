import { useState } from "react"
import { useUserToken } from "../../token/TokenQuery"
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../../services/BackendClientFactory"
import { IntegrateUpdatedUserResources } from "./UserResourcesIntegration"
import { userResourcesQueryKey } from "./UserResourcesQueries"

export const useSpawnCoinsForFreeMutator = () => {

    const { userToken } = useUserToken()
    const [updating, setUpdating] = useState(false)
    const queryClient = useQueryClient()

    const spawnCoinsMutator = useMutation({
        mutationFn: async (coinAmount : number) => {
            setUpdating(true)
            const updatedSettings = await GetBackendClient(userToken).spawnCoins.spawnCoins({coinAmount: coinAmount.toString()})
            setUpdating(false)
            return updatedSettings.data
        },
        onSuccess: IntegrateUpdatedUserResources(queryClient)
    })

    return { spawnCoinsMutator, spawning: updating }
}

export const invalidateCoins = (queryClient : QueryClient) => {
    queryClient.invalidateQueries({queryKey: [userResourcesQueryKey]})
}