import { useQuery } from "@tanstack/react-query"
import { useUserToken } from "../../token/TokenQuery"
import { GetBackendClient } from "../../../services/BackendClientFactory"

export const userResourcesQueryKey = "userResources"


export const useUserResourcesQuery = () => {

    const { userToken, isAuthenticated } = useUserToken()
    
    const { data } = useQuery({
        queryKey: [userResourcesQueryKey],
        enabled: isAuthenticated,
        queryFn: async () => {
            const resp = await GetBackendClient(userToken).getUserResources.getUserResources()
            
            return resp.data
        }
    })

    return { userResources: data }
}