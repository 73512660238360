import { UseQueryOptions, useQueries, useQuery } from "@tanstack/react-query";
import { GetBackendClient } from "../../services/BackendClientFactory";
import { useUserToken } from "../token/TokenQuery";
import { TaleIdResource, TaleResource } from "../../generated/BackendApiClient";

export const userTaleKey = "userTales"
export const taleKey = "tales"

export const useUserTalesQuery = () => {

  const { userToken, isAuthenticated } = useUserToken()

  const { data: talePermissions = [] } = useQuery({
    queryKey: [userTaleKey],
    queryFn: async () => {
      const resp = await GetBackendClient(userToken).getTalesOfUser.getTalesOfUser()
      return resp.data.talePermissions
    },
    enabled: isAuthenticated
  })

  return { talePermissions }
}

export const useTalesByIdQuery = (taleId: TaleIdResource) => {

  const { userToken, isAuthenticated } = useUserToken()

  const { data: tale } = useQuery({
    queryKey: [taleKey, taleId.id],
    queryFn: async () => {
      const resp = await GetBackendClient(userToken).getTaleJson.getTaleJson(taleId.id)
      return resp.data
    },
    enabled: isAuthenticated
  })

  return { tale }
}

export const useAllTalesQuery = () => {

  const { userToken, isAuthenticated } = useUserToken()
  const { talePermissions } = useUserTalesQuery()

  const userQueries = useQueries(
    {
      queries: (talePermissions).map(talePermission => {
        return {
          queryKey: [taleKey, talePermission.id.id],
          queryFn: async () => {
            const resp = await GetBackendClient(userToken).getTaleJson.getTaleJson(talePermission.id.id)
            return resp.data
          },
          enabled: isAuthenticated && !!talePermissions,
        } as UseQueryOptions<TaleResource, unknown, unknown, (string | TaleIdResource)[]>
      })
    }
  )

  const tales = userQueries.filter(q => q.isSuccess).map(q => q.data).filter(tale => tale !== undefined) as TaleResource[]
  const doneLoading = userQueries.filter(q => q.isLoading).length === 0 && !!talePermissions
  const errors = userQueries.filter(q => q.isError).map(q => q.error)

  return { tales, doneLoading, errors }
}