import { QueryClient } from "@tanstack/react-query";
import { betaInfoQueryKey } from "./BetaQueries";
import { BetaInfoResource } from "../../generated/BackendApiClient";

export const integrateConfirmationOf = (userId: string, queryClient: QueryClient) => {
    queryClient.setQueryData([betaInfoQueryKey], (data: BetaInfoResource): BetaInfoResource => ({
        ...data,
        applications: data.applications.filter(a => a.userId !== userId)
    }))
}

export const integrateCeilingChange = (ceiling: number, queryClient: QueryClient) => {
    queryClient.setQueryData([betaInfoQueryKey], (data: BetaInfoResource): BetaInfoResource => ({
        ...data,
        betaUserCeiling: ceiling
    }))
}

export const invalidateBetaInfo = (queryClient: QueryClient) => {
    queryClient.invalidateQueries({
        queryKey: [betaInfoQueryKey],
        exact: true,
        refetchType: 'all',
    })
}