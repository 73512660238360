import { FC } from "react";
import { useFeatureFlagsQuery } from "../../stores/featureflags/FeatureFlagQueries";
import { Form, Spinner } from "react-bootstrap";
import './FeatureFlagsComponent.css'
import { useFeatureFlagMutator } from "../../stores/featureflags/FeatureFlagMutators";

export const FeatureFlagsComponent: FC = () => {

    const { featureFlags } = useFeatureFlagsQuery()
    const { featureFlagMutator } = useFeatureFlagMutator()

    if (!featureFlags) {
        return <Spinner />
    }

    return (
        <div className="feature-flag-component">
            <h3>Feature Flags</h3>
            <div className="feature-flag-list">
                {featureFlags.map(f => (
                    <Form.Check
                        type="switch"
                        key={f.name}
                        defaultChecked={f.active}
                        id={"feature-flag-switch" + f.name}
                        label={f.name}
                        onChange={() => featureFlagMutator.mutate({ name: f.name, active: !f.active })}
                    />
                ))}
            </div>
        </div>
    )
}