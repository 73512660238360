import { FC, useState } from "react";
import { TaleImageFrame } from "../TaleImageFrame";
import { Button, Spinner } from "react-bootstrap";
import { ItemResource, TaleIdResource } from "../../generated/BackendApiClient";
import { useUpdateItemMutator } from "../../stores/tale/items/ItemMutators";
import { HasTalePermission } from "../../util/Permission";

interface TaleItemViewProps {
    item: ItemResource,
    taleId: TaleIdResource
}

export const TaleItemView: FC<TaleItemViewProps> = ({ item, taleId }) => {

    const { updateItemMutator, updatingItem } = useUpdateItemMutator()

    const [editing, setEditing] = useState<boolean>(false)

    const saveOrEditItem = async () => {
        if (editing) {
            await updateItemMutator.mutateAsync({ item, taleId })
        }

        setEditing(!editing)
    }

    return (
        <div id={'item_' + item.name} style={{ display: "flex", flexDirection: "column", margin: "24px 24px 24px 0px" }}>
            <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "16px" }}>
                <h2>{item.name}</h2>
                <p style={{ marginBottom: "8px", marginLeft: "12px" }}>Item</p>
            </div>

            <div style={{ display: "flex", flexDirection: "row", position: "relative" }}>
                <div>
                    <div style={{ float: "left", height: "max-content", alignItems: "center", display: "flex", flexDirection: "column", margin: "0px 12px 12px 0px", backgroundColor: "#b93f45" }}>
                        <div style={{ margin: "8px", width: "max-content" }}>
                            <TaleImageFrame taleId={taleId} visualDescription={item.visualDescription} />
                        </div>
                    </div>
                    <div onBlur={(event) => item.description = event.currentTarget.innerText} suppressContentEditableWarning={true}
                        contentEditable={editing}>
                        {item.description}
                    </div>
                    {HasTalePermission(taleId, "EditTale") ? <Button disabled={updatingItem} onClick={saveOrEditItem}>{editing ? "Save" : "Edit Item"}</Button> : <></>}
                    {updatingItem && <Spinner />}
                </div>
            </div>
        </div>
    )
}