import { FC, useState } from "react"
import { TaleRegionView } from "./TaleVisualizations/TaleRegionView"
import { TaleCharacterView } from "./TaleVisualizations/TaleCharacterView"
import { Button, Spinner } from "react-bootstrap"
import { TaleCampaignView } from "./TaleVisualizations/TaleCampaignView"
import { useCreateRegionsMutator } from "../stores/tale/regions/RegionsMutators"
import { useCreateCampaignMutator } from "../stores/tale/campaign/CampaignMutators"
import { useCreateCharacterMutator } from "../stores/tale/characters/CharacterMutators"
import { TableOfContent } from "./TableOfContent"
import { ProposalResource, TaleResource } from "../generated/BackendApiClient"
import { ProposalGenerator } from "./ProposalGenerator"
import { TaleMetaData } from "./TaleVisualizations/TaleMetaData"
import { useCreateSpeciesMutator } from "../stores/tale/species/SpeciesMutators"
import { TaleSpeciesView } from "./TaleVisualizations/TaleSpeciesView"
import { TaleItemView } from "./TaleVisualizations/TaleItemView"
import { useCreateItemMutator } from "../stores/tale/items/ItemMutators"
import { CostingButton } from "./CostingButton"
import { HasTalePermission } from "../util/Permission"
import { ManualPrompt } from "./ManualPrompt"

interface TaleHtmlProps {
    tale: TaleResource
}

export const TaleHtml: FC<TaleHtmlProps> = ({ tale }) => {
    const { createRegionsMutator, creatingRegions } = useCreateRegionsMutator()
    const { createCharacterMutator, creatingCharacter } = useCreateCharacterMutator()
    const { createCampaignMutator, creatingCampaign } = useCreateCampaignMutator()
    const { createSpeciesMutator, creatingSpecies } = useCreateSpeciesMutator()
    const { createItemMutator, creatingItem } = useCreateItemMutator()

    const [showCreateRegion, setShowCreateRegion] = useState(false)
    const [showCreateCharacter, setShowCreateCharacter] = useState(false)
    const [showCreateSpecies, setShowCreateSpecies] = useState(false)
    const [showCreateItem, setShowCreateItem] = useState(false)

    const createNewRegion = async (proposal: ProposalResource) => createRegionsMutator.mutateAsync({ taleId: tale.id, proposal }).then(_ => setShowCreateRegion(false))
    const createNewCharacter = async (proposal: ProposalResource) => createCharacterMutator.mutateAsync({ taleId: tale.id, proposal }).then(_ => setShowCreateCharacter(false))
    const createNewSpecies = async (proposal: ProposalResource) => createSpeciesMutator.mutateAsync({ taleId: tale.id, proposal }).then(_ => setShowCreateSpecies(false))
    const createNewItem = async (proposal: ProposalResource) => createItemMutator.mutateAsync({ taleId: tale.id, proposal }).then(_ => setShowCreateItem(false))
    const createNewCampaign = async () => createCampaignMutator.mutate(tale.id)

    return (
        <div style={{ maxWidth: 900, textAlign: "justify" }}>
            <div id="world" style={{ textAlign: "center", marginTop: "24px" }}>
                <h1 style={{ fontWeight: "bold" }}>{tale.world.name}</h1>
                <p>{tale.world.description}</p>
            </div>
            <TableOfContent tale={tale} />
            <TaleMetaData tale={tale} />
            <div id="regions" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                <h2>Regions</h2>
                {tale.regions.map(region => <TaleRegionView key={region.name} region={region} taleId={tale.id} />)}
                {HasTalePermission(tale.id, "EditTale") ? <Button style={{ margin: "12px 0px 12px 0px", maxWidth: "30%" }} disabled={creatingRegions || showCreateRegion} onClick={() => setShowCreateRegion(true)}>Create new Region</Button> : <></>}
                {showCreateRegion && <ManualPrompt onSelectedProposal={createNewRegion} costSelection={c => c?.regionCreation} />}
                {showCreateRegion && <h3>Generate Proposals</h3>}
                {showCreateRegion && <ProposalGenerator taleId={tale.id} onSelectedProposal={createNewRegion} costSelection={c => c?.regionCreation} type="Region" />}
                {creatingRegions ? <div>Creating Region... <Spinner /></div> : <></>}
            </div>
            <div id="species" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                <h2>Species</h2>
                {tale.species.map(species => <TaleSpeciesView key={species.name} species={species} taleId={tale.id} />)}
                {HasTalePermission(tale.id, "EditTale") ? <Button style={{ margin: "12px 0px 12px 0px", maxWidth: "30%" }} disabled={creatingSpecies || showCreateSpecies} onClick={() => setShowCreateSpecies(true)}>Create new Species</Button> : <></>}
                {showCreateSpecies && <ManualPrompt onSelectedProposal={createNewSpecies} costSelection={c => c?.speciesCreation} />}
                {showCreateSpecies && <h3>Generate Proposals</h3>}
                {showCreateSpecies && <ProposalGenerator taleId={tale.id} onSelectedProposal={createNewSpecies} costSelection={c => c?.speciesCreation} type="Species" />}
                {creatingSpecies ? <div>Creating Species... <Spinner /></div> : <></>}
            </div>
            <div id="characters" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                <h2>Characters</h2>
                {tale.characters.map(character => <TaleCharacterView key={character.name} character={character} taleId={tale.id} />)}
                {HasTalePermission(tale.id, "EditTale") ? <Button style={{ margin: "12px 0px 12px 0px", maxWidth: "30%" }} disabled={creatingCharacter || showCreateCharacter} onClick={() => setShowCreateCharacter(true)}>Create new Character</Button> : <></>}
                {showCreateCharacter && <ManualPrompt onSelectedProposal={createNewCharacter} costSelection={c => c?.characterCreation} />}
                {showCreateCharacter && <h3>Generate Proposals</h3>}
                {showCreateCharacter && <ProposalGenerator taleId={tale.id} onSelectedProposal={createNewCharacter} costSelection={c => c?.characterCreation} type="Character" />}
                {creatingCharacter ? <div>Creating Character... <Spinner /></div> : <></>}
            </div>
            <div id="items" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                <h2>Items</h2>
                {tale.items.map(item => <TaleItemView key={item.name} item={item} taleId={tale.id} />)}
                {HasTalePermission(tale.id, "EditTale") ? <Button style={{ margin: "12px 0px 12px 0px", maxWidth: "30%" }} disabled={creatingItem || showCreateItem} onClick={() => setShowCreateItem(true)}>Create new Item</Button> : <></>}
                {showCreateItem && <ManualPrompt onSelectedProposal={createNewItem} costSelection={c => c?.itemCreation} />}
                {showCreateItem && <h3>Generate Proposals</h3>}
                {showCreateItem && <ProposalGenerator taleId={tale.id} onSelectedProposal={createNewItem} costSelection={c => c?.itemCreation} type="Item" />}
                {creatingItem ? <div>Creating Item... <Spinner /></div> : <></>}
            </div>
            <div id="campaigns" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                <h2>Campaigns</h2>
                {tale.campaigns.map(campaign => <TaleCampaignView key={campaign.story.name} campaign={campaign} taleId={tale.id} />)}
                {creatingCampaign ? <div>Creating Campaign... <Spinner /></div> : <></>}
                {HasTalePermission(tale.id, "EditTale") ? <CostingButton
                    style={{ margin: "12px 0px 12px 0px", maxWidth: "30%" }}
                    onClick={createNewCampaign}
                    text={"Create new Campaign"}
                    costSelector={c => c?.campaignCreation}
                    disabled={creatingCampaign}
                /> : <></>}
            </div>
        </div>
    )
}