import { useState } from "react"
import { useUserToken } from "../../token/TokenQuery"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../../services/BackendClientFactory"
import { IntegrateUpdatedTale } from "../TaleIntegrations"
import { ItemResource, ProposalResource, TaleIdResource } from "../../../generated/BackendApiClient"
import { useUserSettingsQuery } from "../../user/settings/UserSettingsQueries"
import { invalidateCoins } from "../../user/resources/UserResourceMutators"

type ItemCreationArgs = {
    taleId: TaleIdResource,
    proposal: ProposalResource
}

export const useCreateItemMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [creating, setCreating] = useState(false)

    const createItemMutator = useMutation({
        mutationFn: async (args: ItemCreationArgs) => {
            setCreating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).createItem.createItem(args.taleId.id, args.proposal)
            invalidateCoins(queryClient)
            setCreating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { createItemMutator, creatingItem: creating }
}

type UpdateItem = { item: ItemResource, taleId: TaleIdResource }
export const useUpdateItemMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [updating, setUpdating] = useState(false)

    const updateItemMutator = useMutation({
        mutationFn: async (updateItem: UpdateItem) => {
            setUpdating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).updateItem.updateItem(updateItem.taleId.id, updateItem.item)
            setUpdating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { updateItemMutator, updatingItem: updating }
}