import { FC, useState } from "react";
import { CharacterResource, CharacterStatsResource, IdResource, TaleIdResource } from "../../generated/BackendApiClient";
import { useCharacterStatsQuery } from "../../stores/ruleset/RulesetExtensionsQueries";
import { Spinner, Button, Form } from "react-bootstrap";
import { useUpdateCharacterStatsMutator } from "../../stores/ruleset/CharacterStatsMutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faBrain, faDumbbell, faHeart, faShieldHalved, faWind } from "@fortawesome/free-solid-svg-icons"

type CharacterStatsViewProps = {
    taleId: TaleIdResource,
    character: CharacterResource
}

const defautStats = (charId: IdResource): CharacterStatsResource => ({
    characterId: charId,
    armorClass: Math.ceil(Math.random() * 20),
    dexterity: Math.ceil(Math.random() * 20),
    health: Math.ceil(Math.random() * 20),
    intelligence: Math.ceil(Math.random() * 20),
    strength: Math.ceil(Math.random() * 20),
    wisdom: Math.ceil(Math.random() * 20)
})

const hash = (s: CharacterStatsResource) => {
    var entries = Object.entries(s);
    return entries.sort((a, b) => a[0].localeCompare(b[0])).map(kv => kv[1]).join('')
}

const characterStatsEqualsByValue = (s1: CharacterStatsResource | undefined | null, s2: CharacterStatsResource | undefined | null): boolean => {
    if (s1 === s2) {
        return true
    }

    if (s1 === null || s1 === undefined || s2 === null || s2 === undefined) {
        return false
    }

    return hash(s1) === hash(s2)
}

export const CharacterStatsView: FC<CharacterStatsViewProps> = ({ taleId, character }) => {

    const { updateCharacterStatsMutator, updatingCharacterStats } = useUpdateCharacterStatsMutator();
    const { characterStats, isLoading } = useCharacterStatsQuery(taleId, character);
    const [editableStats, setEditableStats] = useState(characterStats);

    const handleChange: React.ChangeEventHandler<any> = (e) => {
        const { name, value } = e.target;
        setEditableStats(prevStats => {
            if (!prevStats) return null;
            return ({
                ...prevStats,
                [name]: Number(value)
            })
        });
    };

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();

        if (!editableStats) throw new Error("Invalid State Exception");

        const newData = { ...editableStats, characterId: character.id }
        updateCharacterStatsMutator.mutate({ taleId: taleId, updatedResource: newData })
    };

    if (updatingCharacterStats || (characterStats === undefined && isLoading)) {
        return <Spinner />
    }

    if (!editableStats) {
        if (!!characterStats) {
            setEditableStats(characterStats)
            return <></>
        } else {
            return <Button onClick={() => setEditableStats(defautStats(character.id))} >Create Stats</Button>;
        }
    }

    return (
        <div className="container mt-5">
            <Form onSubmit={handleSubmit}>
                <table className="table table-bordered table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th>Health <FontAwesomeIcon icon={faHeart} /></th>
                            <th>Armor Class <FontAwesomeIcon icon={faShieldHalved} /></th>
                            <th>Wisdom <FontAwesomeIcon icon={faBook} /></th>
                            <th>Intelligence <FontAwesomeIcon icon={faBrain} /></th>
                            <th>Strength <FontAwesomeIcon icon={faDumbbell} /></th>
                            <th>Dexterity <FontAwesomeIcon icon={faWind} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Form.Control
                                    type="number"
                                    name="health"
                                    value={editableStats.health}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    name="armorClass"
                                    value={editableStats.armorClass}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    name="wisdom"
                                    value={editableStats.wisdom}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    name="intelligence"
                                    value={editableStats.intelligence}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    name="strength"
                                    value={editableStats.strength}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    name="dexterity"
                                    value={editableStats.dexterity}
                                    onChange={handleChange}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Button hidden={characterStatsEqualsByValue(editableStats, characterStats)} variant="primary" type="submit">Save</Button>
            </Form>
        </div>
    );
}
