import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthState } from "../../dtos/AuthState";
import { AuthenticationResponseResource } from "../../generated/BackendApiClient";
import { useMsAuthQuery } from "./MsTokenQuery";
import { useGoogleAuthQuery } from "./GoogleTokenQuery";
import { userSettingsQueryKey } from "../user/settings/UserSettingsQueries";

export const userTokenKey = "userToken"
export const userAuthResourceKey = "userAuthResource"

export const useUserToken = () => {
    const { userToken, allReady: isAuthenticated } = useAuthStateQuery()

    return { userToken, isAuthenticated }
}

export const useAuthStateQuery = () => {
    const { msToken } = useMsAuthQuery()
    const { googleToken } = useGoogleAuthQuery()
    const queryClient = useQueryClient()

    const isAuthenticated = !!msToken || !!googleToken
    const { data: authState } = useQuery({
        queryKey: [userTokenKey],
        queryFn: async () => {
            const userTokenResponse = queryClient.getQueryData([userAuthResourceKey]) as AuthenticationResponseResource

            const authState: AuthState = {
                userToken: userTokenResponse.userToken,
                allReady: !!userTokenResponse.userToken,
                userTokenError: userTokenResponse.errorMessage,
                userSettingsResource: userTokenResponse.userSettingsResource,
                isAuthenticated: true
            }

            queryClient.setQueryData([userSettingsQueryKey], () => authState.userSettingsResource)

            return authState
        },
        enabled: isAuthenticated,
        gcTime: Infinity
    })

    return authState ?? { userToken: undefined, allReady: false, userTokenError: undefined, userSettingsResource: undefined, isAuthenticated }
}