import { FC, ReactNode } from "react";
import './PageComponent.css';
import { Container, Row, Col } from 'react-bootstrap';

interface PageComponentProps {
    backgroundImage: string;
    characterImage: string;
    characterLeft?: boolean;
    characterHeightInPerc?: string;
    characterWidthOf12?: number;
    children: ReactNode;
}

export const PageComponent: FC<PageComponentProps> = ({
    backgroundImage,
    characterImage,
    characterLeft,
    characterHeightInPerc,
    characterWidthOf12,
    children,
}) => {

    const char = (
        <Col xs={characterWidthOf12 ?? 4} className="character-wrapper">
            <img src={characterImage} alt="Character" style={{ height: characterHeightInPerc }} />
        </Col>
    )

    return (
        <section
            className="page-layout-row page-wrapper"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <Container fluid>
                <Row>
                    {characterLeft && char}
                    <Col xs={12 - (characterWidthOf12 ?? 4)} className="content-wrapper">
                        {children}
                    </Col>
                    {!characterLeft && char}
                </Row>
            </Container>
        </section >
    );
};
