import { FC, useState } from "react";
import { TaleResource } from "../../generated/BackendApiClient";
import { useUpdateTaleMutator } from "../../stores/tale/TaleMutators";
import { Button, Spinner } from "react-bootstrap";
import { HasTalePermission } from "../../util/Permission";


export const TaleMetaData : FC<{tale:TaleResource}> = ({tale}) => {
    
    const { updateTaleMutator, updatingTale } = useUpdateTaleMutator()

    const [editing, setEditing] = useState<boolean>(false)

    const saveOrEditEncounter = async () => {
        if (editing) {
            await updateTaleMutator.mutateAsync({ tale })
        } 
        
        setEditing(!editing)
    }

    return (
        <div id="metatale" style={{marginTop:40}}>
            <h3>Culture and Civilizations</h3>
            <div onBlur={(event) => tale.world.attributes.cultureAndCivilizations = event.currentTarget.innerText} suppressContentEditableWarning={true}
                contentEditable={editing}>
                {tale.world.attributes.cultureAndCivilizations}
            </div>
            <h3>History and Background</h3>
            <div onBlur={(event) => tale.world.attributes.historyAndBackground = event.currentTarget.innerText} suppressContentEditableWarning={true}
                contentEditable={editing}>
                {tale.world.attributes.historyAndBackground}
            </div>
            <h3>TechnologyLevel</h3>
            <div onBlur={(event) => tale.world.attributes.technologyLevel = event.currentTarget.innerText} suppressContentEditableWarning={true}
                contentEditable={editing}>
                {tale.world.attributes.technologyLevel}
            </div>
            <h3>Geography</h3>
            <div onBlur={(event) => tale.world.attributes.geography = event.currentTarget.innerText} suppressContentEditableWarning={true}
                contentEditable={editing}>
                {tale.world.attributes.geography}
            </div>
            <h3>Economy</h3>
            <div onBlur={(event) => tale.world.attributes.economy = event.currentTarget.innerText} suppressContentEditableWarning={true}
                contentEditable={editing}>
                {tale.world.attributes.economy}
            </div>
            <h3>Mythology and Magic</h3>
            <div onBlur={(event) => tale.world.attributes.mythologyAndMagic = event.currentTarget.innerText} suppressContentEditableWarning={true}
                contentEditable={editing}>
                {tale.world.attributes.mythologyAndMagic}
            </div>
            {HasTalePermission(tale.id, "EditTale") ? <Button disabled={updatingTale} onClick={saveOrEditEncounter}>{editing ? "Save" : "Edit Tale Meta Data"}</Button> : <></>}
            {updatingTale && <Spinner/>}
        </div>
    )
}