import { useMutation } from "@tanstack/react-query"
import { useState } from "react"
import { useUserToken } from "../token/TokenQuery"
import { GetBackendClient } from "../../services/BackendClientFactory"


export const useSendBetaEnlistRequestMutator = () => {

    const { userToken } = useUserToken()
    const [updating, setUpdating] = useState(false)

    const sendBetaEnlistRequestMutator = useMutation({
        mutationFn: async (email: string) => {
            setUpdating(true)
            await GetBackendClient(userToken).applyForBeta.applyForBeta({ email: email })
            setUpdating(false)
        }
    })

    return { sendBetaEnlistRequestMutator, sending: updating }
}

