import { useQuery } from "@tanstack/react-query"
import { useUserToken } from "../../token/TokenQuery"
import { GetBackendClient } from "../../../services/BackendClientFactory"

export const userSettingsQueryKey = "userSettings"


export const useUserSettingsQuery = () => {

    const { userToken, isAuthenticated } = useUserToken()
    
    const { data } = useQuery({
        queryKey: [userSettingsQueryKey],
        enabled: isAuthenticated,
        queryFn: async () => {
            const resp = await GetBackendClient(userToken).getUserSettings.getUserSettings()
            
            return resp.data
        }
    })

    return { userSettings: data }
}