import { FC } from "react"
import { useCreateTaleMutator } from "../../stores/tale/TaleMutators"
import { Card, Spinner } from "react-bootstrap"
import { ProposalGenerator } from "../../components/ProposalGenerator"
import { useNavigate } from "react-router"
import { ManualPrompt } from "../../components/ManualPrompt"
import { PageComponent } from "../../components/PageComponent"

export const TaleCreation: FC = () => {

  const { createTaleMutator, creatingTale } = useCreateTaleMutator()
  const nav = useNavigate()

  return (
    <PageComponent
      backgroundImage={process.env.PUBLIC_URL + '/img/library.png'}
      characterImage={process.env.PUBLIC_URL + '/img/bard.png'}
    >
      <>
        <Card className="scrollableBody" style={{ width: '90%' }}>
          <Card.Header><h3>What should the tale be about?</h3></Card.Header>
          <Card.Body>
            <p>Write a proposal yourself...</p>
            <ManualPrompt onSelectedProposal={proposal => createTaleMutator.mutateAsync(proposal).then(tale => nav(`/tale/${tale.id.id}`))} costSelection={c => c?.taleCreation} />
            <br />
            <p>... or generate proposals</p>
            <ProposalGenerator taleId={undefined} type='Tale' costSelection={c => c?.taleCreation} onSelectedProposal={proposal => createTaleMutator.mutateAsync(proposal).then(tale => nav(`/tale/${tale.id.id}`))} />
            {creatingTale ? <div>Creating the new Tale...<Spinner /></div> : <></>}
          </Card.Body>
        </Card>
      </>
    </PageComponent>
  )
}