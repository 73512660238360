import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useUserToken } from "../../token/TokenQuery"
import { GetBackendClient } from "../../../services/BackendClientFactory"
import { UserSettingsResource } from "../../../generated/BackendApiClient"
import { IntegrateUpdatedUserSettings } from "./UserSettingsIntegrations"


export const useUpdateUserSettingsMutator = () => {

    const { userToken } = useUserToken()
    const [updating, setUpdating] = useState(false)
    const queryClient = useQueryClient()

    const updateUserSettingsMutator = useMutation({
        mutationFn: async (userSettingsResource : UserSettingsResource) => {
            setUpdating(true)
            const updatedSettings = await GetBackendClient(userToken).updateSettings.updateSettings(userSettingsResource)
            setUpdating(false)
            return updatedSettings.data
        },
        onSuccess: IntegrateUpdatedUserSettings(queryClient)
    })

    return { updateUserSettingsMutator, updatingSettings: updating }
}

