import { FC } from "react"
import './Forge.css'
import { PageComponent } from "../../components/PageComponent"
import { Card, CardBody, CardHeader } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFire } from "@fortawesome/free-solid-svg-icons"

export const Forge: FC = () => {
    return (
        <PageComponent
            backgroundImage={process.env.PUBLIC_URL + '/img/forge.png'}
            characterImage={process.env.PUBLIC_URL + '/img/smith.png'}
            characterWidthOf12={5}
            characterLeft
        >
            <>
                <Card>
                    <CardHeader><h3>The Forge <FontAwesomeIcon icon={faFire} /></h3></CardHeader>
                    <CardBody>
                        Nothing here yet...
                    </CardBody>
                </Card>
            </>
        </PageComponent>
    )
}