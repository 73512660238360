import { FC } from "react";
import { useCostsQuery } from "../stores/costs/CostQueries";
import { CoinCostResource } from "../generated/BackendApiClient";
import { Button } from "react-bootstrap";
import { useUserResourcesQuery } from "../stores/user/resources/UserResourcesQueries";
import { Link } from "react-router-dom";

type CostingButtonProps = {
    onClick: () => void,
    costSelector: (costs: CoinCostResource | undefined) => number | undefined,
    text: string,
    style?: React.CSSProperties | undefined,
    disabled?: boolean
}

export const CostingButton : FC<CostingButtonProps> = ({
    onClick,
    costSelector,
    text,
    style,
    disabled
}) => {

    const { userResources } = useUserResourcesQuery()
    const { costs } = useCostsQuery()
    const cost = costSelector(costs)
    const hasEnoughCoins = (cost ?? Infinity) <= (userResources?.coins ?? 0)

    return (
        <>
            <Button
                style={style}
                onClick={onClick}
                disabled={!hasEnoughCoins || (disabled ?? false)}
            >
                {text} ({cost ?? '?'} Coins)
            </Button>
            {   !hasEnoughCoins &&
                <Link to={'/Market'}>
                    <Button>
                        Buy more 🪙
                    </Button>
                </Link>
            }
        </>
    )
}