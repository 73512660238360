import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserToken } from "../token/TokenQuery";
import { GetBackendClient } from "../../services/BackendClientFactory";
import { useState } from "react";
import { integrateCeilingChange, integrateConfirmationOf, invalidateBetaInfo } from "./BetaIntegrators";
import { betaInfoQueryKey } from "./BetaQueries";

export const useConfirmApplicationMutator = () => {

    const { userToken } = useUserToken()
    const [confirming, setConfirming] = useState(false)
    const queryClient = useQueryClient()

    const confirmApplicationMutator = useMutation({
        mutationFn: async (userId: string) => {
            setConfirming(true)
            try {
                await GetBackendClient(userToken).confirmBetaApplication.confirmBetaApplication({ userId: userId })

            } catch (error) {
                throw error

            } finally {
                setConfirming(false)
            }
        },
        onMutate: (userId: string) => integrateConfirmationOf(userId, queryClient),
        onSuccess: () => invalidateBetaInfo(queryClient),
        onError: () => invalidateBetaInfo(queryClient),
    })

    return { confirmApplicationMutator, confirming: confirming }
}

export const useUpdateCeilingMutator = () => {

    const { userToken } = useUserToken()
    const [updating, setUpdating] = useState(false)
    const queryClient = useQueryClient()

    const ceilingMutator = useMutation({
        mutationFn: async (ceiling: number) => {
            setUpdating(true)
            try {
                await GetBackendClient(userToken).betaTesterCeiling.setBetaTesterCeiling({ ceiling: ceiling.toString() })
            } catch (error) {
                throw error
            } finally {
                setUpdating(false)
            }
        },
        onMutate: (ceiling) => integrateCeilingChange(ceiling, queryClient),
        onSuccess: () => invalidateBetaInfo(queryClient),
        onError: () => invalidateBetaInfo(queryClient),
    })

    return { ceilingMutator: ceilingMutator, updating: updating }
}

export const useRefreshBetaInfoMutator = () => {

    const queryClient = useQueryClient()

    const refreshBetaInfoMutator = useMutation({
        mutationFn: async () => {
            await queryClient.resetQueries({
                queryKey: [betaInfoQueryKey],
                exact: true,
            })
        }
    })

    return { refreshBetaInfoMutator: refreshBetaInfoMutator }
}