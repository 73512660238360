import { useQuery } from "@tanstack/react-query"
import { useUserToken } from "../token/TokenQuery"
import { GetBackendClient } from "../../services/BackendClientFactory"

export const featureFlagQueryKey = "featureFlags"


export const useFeatureFlagsQuery = () => {

    const { userToken, isAuthenticated } = useUserToken()

    const { data } = useQuery({
        queryKey: [featureFlagQueryKey],
        enabled: isAuthenticated,
        queryFn: async () => {
            const resp = await GetBackendClient(userToken).featureFlags.featureFlags()
            return resp.data
        },
    })
    data?.sort((a, b) => a.name.localeCompare(b.name))
    return { featureFlags: data }
}

export const useIsFeatureFlagEnabled = (featureFlagName: string) => {
    const { featureFlags } = useFeatureFlagsQuery()

    return featureFlags?.find(f => f.name === featureFlagName)?.active ?? false
}