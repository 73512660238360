import { FC } from "react"
import './MageTower.css'
import { UserSettings } from "../../components/UserSettings"
import { faMagic } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, CardHeader, CardBody } from "react-bootstrap"
import { PageComponent } from "../../components/PageComponent"

export const MageTower: FC = () => {
    return (
        <PageComponent
            backgroundImage={process.env.PUBLIC_URL + '/img/magetower.png'}
            characterImage={process.env.PUBLIC_URL + '/img/mage.png'}
            characterWidthOf12={5}
        >
            <Card>
                <CardHeader><h3>The Mages Tower <FontAwesomeIcon icon={faMagic} /></h3></CardHeader>
                <CardBody>
                    <UserSettings />
                </CardBody>
            </Card>
        </PageComponent>
    )
}