import { FC, useRef } from "react";
import { useUpdateUserSettingsMutator } from "../stores/user/settings/UserSettingsMutator";
import { Button, Spinner, Table } from "react-bootstrap";
import { useUserSettingsQuery } from "../stores/user/settings/UserSettingsQueries";
import { useQueryClient } from "@tanstack/react-query";
import { InvalidateCosts } from "../stores/costs/CostMutators";

export const UserSettings: FC = () => {

    const { userSettings } = useUserSettingsQuery()
    const { updateUserSettingsMutator, updatingSettings } = useUpdateUserSettingsMutator()
    const queryClient = useQueryClient()
    const preferredImageStyleInput = useRef<HTMLInputElement>(null)
    const userNameInput = useRef<HTMLInputElement>(null)

    if (userSettings === undefined) {
        return <div style={{ display: "flex", alignItems: "center", flexDirection: "column", width: '90%', marginTop: "30%" }}>Loading Settings... <Spinner /></div>
    }

    const updateImageStyle = async (style: string) => {
        await updateUserSettingsMutator.mutateAsync({ ...userSettings, preferredImageStyle: style })
    }

    const updateName = async (name: string) => {
        await updateUserSettingsMutator.mutateAsync({ ...userSettings, userName: name })
    }

    const updateModel = async (newModel: string) => {
        await updateUserSettingsMutator.mutateAsync({ ...userSettings, openAiModelType: newModel })
        InvalidateCosts(queryClient)
    }

    const models = [{ value: "Fast", key: "Speed" }, { value: "Fancy", key: "Quality" }]

    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", width: '90%', }}>
            <Table>
                <tbody>
                    <tr>
                        <td><label htmlFor="userName">UserName: </label></td>
                        <td><input ref={userNameInput} type="text" name="userName" defaultValue={userSettings.userName} /></td>
                        <td><Button onClick={_ => updateName(userNameInput.current!.value)}>Update</Button></td>
                    </tr>
                    <tr>
                        <td><label htmlFor="preferredImageStyle">Preferred Image Style: </label></td>
                        <td><input ref={preferredImageStyleInput} type="text" name="preferredImageStyle" defaultValue={userSettings.preferredImageStyle} /></td>
                        <td><Button onClick={_ => updateImageStyle(preferredImageStyleInput.current!.value)}>Update</Button></td>
                    </tr>
                    <tr>
                        <td><label htmlFor={userSettings.openAiModelType}>Generation Preference: </label></td>
                        <td colSpan={2}><select name="openAiModel" value={userSettings.openAiModelType} onChange={e => updateModel(e.target.value)}>
                            {models.map(model => <option key={model.key} value={model.key}>{model.value}</option>)}
                        </select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>{updatingSettings ? <>Saving Changes... <Spinner /></> : <></>}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}