import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GetDayKey, llmUsageKey } from "./LlmUsageQueries"

export const useRefreshTodaysLLMUsage = () => {
    const queryClient = useQueryClient()

    const refreshTodaysUsageMutator = useMutation({
        mutationFn: () => {
            const dayKey = GetDayKey(new Date())
            queryClient.invalidateQueries({ queryKey: [llmUsageKey, dayKey], exact: true })
            return Promise.resolve()
        }
    })

    return { refreshTodaysUsageMutator }
}